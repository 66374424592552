import { format, quantityFormat as qFormat } from '@/utils/number.js'

/**
 *
 *
 * @param {int|string} value
 * @return strign
 */
function numberFormat (value) {
  return format(value)
}

/**
 *
 * @param {int|string} value
 * @return strign
 */
function quantityFormat (value) {
  return qFormat(value)
}

export { numberFormat }
export { quantityFormat }

export default {
  numberFormat,
  quantityFormat
}
