import { RawConnection } from '@/api/ORM/connection/index'

class Auth {
  static login (body) {
    const connection = new RawConnection()
    const url = '/auth/login'
    return connection.post(url, { email: body.email, password: body.password })
      .then(result => result.data)
  }

  static register (body) {
    const connection = new RawConnection()
    const url = '/auth/register'
    return connection.post(url, {
      name: body.name,
      email: body.email,
      password: body.password,
      password_confirmation: body.password_confirmation
    })
      .then(result => result.data)
  }

  static logout () {
    const connection = new RawConnection()
    const url = '/auth/logout'
    return connection.post(url)
      .then(result => result.data)
  }

  static user () {
    const connection = new RawConnection()
    const url = '/auth/user'
    return connection.post(url)
      .then(result => result.data)
  }
}

export default Auth
