import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./plugins";
import { dictionary } from "./i18n/dictionary";
import VueI18n from "vue-i18n";
import "@/assets/app.css";
import Models from './api/main.js'

import currencyFormat from './filters/currency'
import { quantityFormat, numberFormat } from './filters/number'

Vue.filter('currencyFormat', currencyFormat)
Vue.filter('quantityFormat', quantityFormat)
Vue.filter('numberFormat', numberFormat)


Vue.config.productionTip = false;

Vue.prototype.$Models = Models

const i18n = new VueI18n({
    locale: 'es',
    fallbackLocale: 'es',
    silentTranslationWarn: true,
    messages: dictionary
 })


new Vue({
    router,
    store,
    i18n,
    render: (h) => h(App),
}).$mount("#app");
