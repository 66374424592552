<template>
    <Layout>
        <div>
            <!-- Banner Area -->
            <section id="common_banner_one">
                <div class="container ">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="common_banner_text">
                                <h2>{{ this.title }}</h2>
                                <b-breadcrumb :items="breadcrumbItems" class="bg-transparent"></b-breadcrumb>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <!-- Error-Area -->
            <section id="error_area" class="ptb-100">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-6 offset-lg-3">
                            <div class="erorr_wrapper">
                                <h1>404</h1>
                                <h3>Lo sentimos, la página solicitada no está disponible.!</h3>
                                <router-link to="/" class="theme-btn-one btn-black-overlay btn_md">Volver al inicio</router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </Layout>
</template>

<script>
import Layout from "@/components/layouts/main.vue";
export default {
    name: 'Error404',
    components: {
        Layout
    },
    data() {
        return {
            title: 'Error',
            breadcrumbItems: [
                {
                    text: 'Inicio',
                    to: '/'
                },
                {
                    text: 'Error',
                }
            ],
        }
    },
    mounted() {
        window.scrollTo(0, 0)
    },
    head() {
        return {
            title: this.title,
            meta: [
                {
                    hid: 'description',
                    name: 'description',
                    content: '404 Error - Organic Nails tienda online'
                }
            ]
        }
    }
}
</script>
