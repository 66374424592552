<template>
    <Layout>
        <div>
            <!-- Banner Area -->
            <section id="common_banner_one">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="common_banner_text">
                                <h2>{{ this.title }}</h2>
                                <b-breadcrumb :items="breadcrumbItems" class="bg-transparent"></b-breadcrumb>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <!-- Shop Main Area -->
            <section id="shop_main_area" class="ptb-100">
                <div class="container">
                    <div class="row">
                        <ColorSelector v-if="urlCategorySlug === 'color-gel'" :categorySlug="urlCategorySlug"/>
                        <ColorSelector v-else-if="urlCategorySlug === 'glitter'" :categorySlug="urlCategorySlug"/>
                        <!--ColorSelector v-if="urlCategorySlug === 'glitter'" :categorySlug="urlCategorySlug"/-->

                        <ShopSidebar ref="compShopSidebar" @changeCategory="handlerCategory" />

                        <div class="col-lg-9">
                            <div class="row">
                                <div
                                    class="col-lg-4 col-md-4 col-sm-6 col-12"
                                    v-for="(product, index) in products"
                                    :key="index"
                                >
                                    <ProductCard
                                        :product="product"
                                        :index="index"
                                        @showalert="alert"
                                        @alertseconds="alert"
                                    />
                                </div>

                                <!-- pagination start -->
                                <div class="col-lg-12">
                                    <div
                                        class="product-pagination mb-0"
                                        v-if="showLoadMore"
                                    >
                                        <nav aria-label="Page navigation">
                                            <ul class="pagination">
                                                <li class="page-item">
                                                    <a
                                                        class="page-link"
                                                        href="javascript:void(0)"
                                                        @click="toPrevPage()"
                                                    >
                                                        <span aria-hidden="true">
                                                            <i class="fa fa-chevron-left" style="font-size: 10px;" aria-hidden="true"></i>
                                                        </span>
                                                    </a>
                                                </li>
                                                <li
                                                    class="page-item"
                                                    v-for="(i, index) in pagination.totalPages"
                                                    :key="index"
                                                    :class="{ active: i == pagination.currentPage, }"
                                                    @click="toPage(i)"
                                                >
                                                    <a class="page-link" href="javascript:void(0)">{{ i }}</a>
                                                </li>
                                                <li class="page-item">
                                                    <a
                                                        class="page-link"
                                                        href="javascript:void(0)"
                                                        @click="toNextPage()"
                                                    >
                                                        <span aria-hidden="true">
                                                            <i class="fa fa-chevron-right" style="font-size: 10px;" aria-hidden="true"></i>
                                                        </span>
                                                    </a>
                                                </li>
                                            </ul>
                                        </nav>
                                    </div>
                                </div>
                                <!-- pagination end -->
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </Layout>
</template>

<script>
import { mapState } from "vuex";
import ProductCard from "@/components/molecules/product.card.vue";
import ShopSidebar from "@/components/organism/shop.sidebar.vue";
import Layout from "@/components/layouts/main.vue";
import LoadingMixin from '@/plugins/Common/LoadingMixin.js'
import ColorSelector from "@/components/molecules/color.selector.vue";

export default {
    name: "Products",
    components: {
        ProductCard,
        ShopSidebar,
        Layout,
        ColorSelector,
    },
    data() {
        return {
            products: [],
            loadingProducts: [],
            urlCategorySlug: null,
            sortBy: 'name ASC',
            filters: {
                category_id: null,
                range_value: null,
                q: false
            },
            title: "Categorías",
            dismissCountDown: 0,

            // Breadcrumb Items Data
            breadcrumbItems: [
                {
                    text: "Inicio",
                    to: "/",
                },
                {
                    text: "Categorías",
                    to: "/categories",
                },
            ],

            //Paginaion
            current: 1,
            paginate: 12,
            paginateRange: 3,
            pages: [],
            paginates: "",
            filters: {
                q: null,
                category_id: null,
                range_value: null,
                brand_id: null
            },
            pagination: {
                currentPage: 1,
                totalRows: 1,
                totalPages: 1,
                perPage: 12
            },
        };
    },
    computed: {
        showLoadMore () {
            return this.pagination.totalPages > 1
        }
    },
    mounted() {
        if (this.$route.params.categoryStoreSlug) { // check later
            this.urlCategorySlug = this.$route.params.categoryStoreSlug
            this.$refs.compShopSidebar.setCategoryBySlug(this.urlCategorySlug)
            this.$refs.compShopSidebar.setShowFilterMobile(false)
        }

        if (this.$route.query.q) {
            this.filters.q = decodeURIComponent(this.$route.query.q)
            this.$refs.compShopSidebar.setQValue(decodeURIComponent(this.$route.query.q))
            this.$refs.compShopSidebar.setShowFilterMobile(false)
        }

        this.load()

        // For scroll page top for every Route
        window.scrollTo(0, 0);

    },
    beforeRouteEnter (to, from, next) {
        next(vm => {
            if (to.params.categoryStoreSlug) {
                vm.urlCategorySlug = to.params.categoryStoreSlug
                vm.$refs.compShopSidebar.setCategoryBySlug(vm.urlCategorySlug)
                vm.$refs.compShopSidebar.setShowFilterMobile(false)
            }
        })
    },
    methods: {
        // Product added Alert / notificaion
        /*
        alert(item) {
            this.dismissCountDown = item;
        },
        */
        // For Shop Left Shorting and Up Shorting
        applySortBy() {
        },
        handlerCategory(data){
            switch (data.type) {
                case 'search':
                    this.filters.category_id = null
                    this.urlCategorySlug = null

                    this.filters.q = data.value
                    this.filter()
                    break;
                case 'category':
                    this.filters.q = null

                    this.filters.category_id = data.value
                    this.urlCategorySlug = data.slug
                    this.filter()
                    break;
                case 'price':
                    this.filters.range_value = data.value
                    this.filter()
                    break;
                case 'sort':
                    this.sortBy = data.value
                    this.filter()
                    break;
            }
        },
        alert(item) {
            this.$swal({
                title: "Excelente",
                text: "El producto se ha agregado al carrito",
                icon: "success",
                showCancelButton: true,
                confirmButtonColor: "#EFC437",
                cancelButtonColor: "#000",
                cancelButtonText: "Seguir comprando",
                confirmButtonText: "Ir al carrito",
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$router.push('/cart')
                }
            });
        },
        toPage (nextPage) {
            if (nextPage <= this.pagination.totalPages) {
                this.pagination.currentPage = nextPage
                this.load()
            }
        },
        toNextPage () {
            const nextPage = this.pagination.currentPage + 1
            if (nextPage <= this.pagination.totalPages) {
                this.pagination.currentPage = nextPage
                this.load()
            }
        },
        toPrevPage () {
            const prevPage = this.pagination.currentPage - 1
            if (prevPage >= 1) {
                this.pagination.currentPage = prevPage
                this.load()
            }
        },
        filter () {
            this.products = []
            this.pagination.currentPage = 1

            this.load()
        },
        load () {
            if (this.statusIsLoading()) return

            const query = this.$Models.Product.query()

            const qValue = this.filters.q
            if (qValue !== null && qValue !== undefined) {
                query.where('q', qValue)
            }

            const categoryValue = this.filters.category_id
            if (categoryValue !== null && categoryValue !== undefined) {
                query.where('category_id', categoryValue)
            } else {
                if (this.urlCategorySlug) {
                    query.where('category_slug', this.urlCategorySlug)
                }
            }
            const rangeValue = this.filters.range_value

            if (rangeValue !== null && rangeValue !== undefined) {
                if (rangeValue.join(',') !== '0,0') {
                    query.where('range_value', rangeValue.join(','))
                }
            }

            const brandValue = this.filters.brand_id
            if (brandValue !== null && brandValue !== undefined) {
                query.where('brand_id', brandValue)
            }

            query.forPage(this.pagination.currentPage, this.pagination.perPage)

            if (this.sortBy) {
                query.orderBy(this.sortBy)
            }

            this.products = []

            this.statusLoading()
            query.get()
                .then(res => {
                if (this.pagination.currentPage === 1) {
                    this.pagination.totalRows = res.count
                    this.pagination.totalPages = Math.floor(this.pagination.totalRows / this.pagination.perPage)
                }
                return res
                })
                .then(res => {
                    this.products = res.items
                    this.statusLoadingComplete()
                })
                .catch((err) => {
                    this.statusLoadingFail()
                    this.showError(err)
                })
        },
    },
    mixins: [LoadingMixin],
    // Page head() Title, description for SEO
    head() {
        return {
            title: this.title,
            meta: [
                {
                    hid: "description",
                    name: "description",
                    content: "Productos - Organic Nails Tienda oficial",
                },
            ],
        };
    },
};
</script>
