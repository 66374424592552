import Service from '@/api/ORM/connection/axios'

export default class Raw {
  constructor () {
    this.service = Service
  }

  sendRequest (url, method, body) {
    const key = method || 'get'
    return this.service[key](url, body)
  }

  get (url, body) {
    return this.sendRequest(url, 'get', body)
  }

  post (url, body) {
    return this.sendRequest(url, 'post', body)
  }

  put (url, body) {
    return this.sendRequest(url, 'put', body)
  }

  delete (url, body) {
    return this.sendRequest(url, 'delete', body)
  }
}
