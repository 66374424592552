<template>
    <Layout>
        <div>
            <!-- Banner Area -->
            <section id="common_banner_one">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="common_banner_text">
                                <h2>{{ this.title }}</h2>
                                <b-breadcrumb :items="breadcrumbItems" class="bg-transparent"></b-breadcrumb>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <!-- Order Area -->
            <section style="margin: 10px; padding: 50px;">
                <div
                    show
                    variant="success"
                >
                    <h3 class="mb-3">Revisa tu correo para completar tu compra</h3>

                    <h4>Compra en Proceso</h4>
                </div>

                <div show
                    color="black"
                    border="left"
                    elevation="5"
                    colored-border
                    icon="mdi-cash"
                >
                    <p style="white-space: pre-line;">{{company.payment_transfer_started_text}}</p>
                    <br>
                </div>
                <!--div show variant="info" outlined>
                    <p>No olvides que es necesario enviar el justificante del pago al Whatsapp 3054247671 para agilizar el proceso de envío.</p>
                    <p>Si tienes dudas puedes contactarnos por Whatsapp.</p>
                </div>

                <div show
                    color="black"
                    border="left"
                    elevation="5"
                    colored-border
                    icon="mdi-cash"
                >
                    <span>PAGO MANUAL POR TRANSFERENCIA U OTROS MEDIOS DE PAGO COMO NEQUI, DAVIPLATA Y MÁS</span>
                    <br>
                    <span>(Te enviaremos un correo con todas las intrucciones para realizar tu pago manual)</span>
                    <br>

                    <div>
                    <br>
                    <span>CONTRA ENTREGA ( SOLO BOGOTA )</span>
                    <br>
                    <span>NEQUI ( 3054247671 )</span>
                    <br>
                    <span>DAVIPLATA ( 3054247671 )</span>
                    <br>
                    <span>BANCOLOMBIA ( Escriba por Whatsapp para enviarle toda la información )</span>
                    <br>
                    <br>
                    <strong style="color: black;">* Es necesario enviar el justificante del pago al Whatsapp 3054247671 para agilizar el proceso de envío.</strong>
                    <br>
                    </div>
                </div-->

                <button
                    @click="goToIndex()"
                    class="theme-btn-one btn-black-overlay btn_sm"
                >
                    Volver
                </button>
            </section>

        </div>
    </Layout>
</template>

<script>
import Layout from "@/components/layouts/main.vue";
import { mapGetters } from "vuex";
import { mapState } from "vuex";

export default {
    name: "OrderComplete",
    data() {
        return {
            title: "Orden Completa",
            // Breadcrumb Items Data
            breadcrumbItems: [
                {
                    text: "Inicio",
                    to: "/",
                },
                {
                    text: "Orden Completa",
                    to: "/orders/complete",
                },
            ],
        };
    },
    components: {
        Layout,
    },
    mounted() {
        // For scroll page top for every Route
        window.scrollTo(0, 0);
    },
    methods: {
        goToIndex () {
            this.$router.push('/')
        }
    },
    computed: {
        ...mapGetters({
            company: "general/allInfo",
        }),
    },
    head() {
        return {
            title: this.title,
            meta: [
                {
                    content: "Orden Completa - Organic Nails tienda oficial",
                },
            ],
        };
    },
};
</script>
<style>
</style>
