import { RawConnection } from '@/api/ORM/connection/index'

class GiftCard {
  static pay (body) {
    const connection = new RawConnection()
    const url = '/api/v1/giftCard/pay'
    return connection.post(url, body)
      .then(result => result.data)
  }
}

export default GiftCard
