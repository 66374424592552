<template>
    <Layout>
        <div>
            <!-- Banner Area -->
            <section id="common_banner_one">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="common_banner_text">
                                <h2 v-if="!pageIsValid">Pagina</h2>

                                <h2 v-if="legalInfo">{{ legalInfo.name }}</h2>
                                <b-breadcrumb :items="breadcrumbItems" class="bg-transparent"></b-breadcrumb>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <!-- text Area -->
            <section style="margin: 10px; padding: 50px;">

            <div v-if="!pageIsValid">
                <h3>La pagina que esta buscando No Existe</h3>
            </div>

            <div v-if="legalInfo">
                <pre>
                    {{legalInfo.text}}
                </pre>
            </div>
            </section>
        </div>
    </Layout>
</template>

<script>
import Layout from "@/components/layouts/main.vue";
import { mapGetters } from "vuex";

export default {
    name: "LegalPage",
    data() {
        return {
            title: "Información",
            // Breadcrumb Items Data
            validPages: [
                'terms_and_conditions',
                'gift_card_policies',
                'payment_policies',
                'return_policy',
                'shipping_policies',
                'data_processing_policies',
                'warranty_policy',
                'returns_retracto_policy',
                'exchange_policy',
            ],
            breadcrumbItems: [
                {
                    text: "Inicio",
                    to: "/",
                }
            ],
        };
    },
    components: {
        Layout,
    },
    mounted() {
        // For scroll page top for every Route
        window.scrollTo(0, 0);
    },
    methods: {
    },
    computed: {
        ...mapGetters({
            company: "general/allInfo",
        }),
        legalInfo () {
            if (this.company.legal) {
                if (this.company.legal[this.$route.params.legalName]) {
                    return this.company.legal[this.$route.params.legalName]
                }
            }
            return null
        },
        pageIsValid () {
            if (this.validPages.findIndex(o => o === this.$route.params.legalName) >= 0) {
                return true
            }
            return false
        }
    },
    head() {
        return {
            title: this.title,
            meta: [
                {
                    content: "Información - Organic Nails tienda oficial",
                },
            ],
        };
    },
};
</script>
<style>
</style>
