import Model from '@/api/ORM/Model.js'

import { RawConnection } from '@/api/ORM/connection/index'

class Product extends Model {
  static endpoint = '/api/v1/products'

  static getFilters () {
    const connection = new RawConnection()
    const url = '/api/v1/products/filters'
    return connection.get(url)
      .then(result => result.data.data.result)
  }
}

export default Product
