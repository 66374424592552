const Mixin = {
  data () {
    return {
      status: {
        loading: false
      }
    }
  },
  methods: {
    statusIsLoading () {
      return this.status.loading === true
    },
    getMediaPath (path) {
      try {
        if (path) {
          if (path.charAt(0) === '/') {
            return `https://umedia.liztofiles.us-sjo1.upcloudobjects.com${path}`
          } else {
            return `https://umedia.liztofiles.us-sjo1.upcloudobjects.com/${path}`
          }
        }
        return null
      } catch (e) {
        return null
      }
    },
    statusLoading () {
      this.status.loading = true
      this.$store.dispatch('general/setLoading', this.status.loading)
    },
    statusLoadingComplete () {
      this.status.loading = false
      this.$store.dispatch('general/setLoading', this.status.loading)
    },
    statusLoadingFail () {
      this.status.loading = false
      this.$store.dispatch('general/setLoading', this.status.loading)
    },
    showError (error) {
      let message

      if (typeof error === 'string') {
        message = error
      } else {
        try {
          console.log('error', error)
          message = error.response.data.message
        } catch {
          if (error.message) {
            message = error.message
          } else {
            message = 'Ha ocurrido un Error'
          }
        }
      }

      this.$swal({
        title: "Error",
        text: message,
        icon: "error",
        confirmButtonColor: "#EFC437",
      });
    }
  }
}

export default Mixin
