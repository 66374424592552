const Mixin = {
  data () {
    return {
      scriptGTagAdded: false,
      siteGTagsKey: null
    }
  },
  methods: {
    wathInfoSiteGTagsKeyGTag (nVal, oVal) {
      if (nVal.site_gtag_key) {
        this.siteGTagsKey = nVal.site_gtag_key
        this.appendScriptGTag()
      }
    },
    appendScriptGTag () {
      if (this.scriptGTagAdded) { return }

      const tKeys = this.siteGTagsKey.split(',')
      const mainKey = tKeys[0]

      this.scriptGTagAdded = true

      const gTagScript = document.createElement('script')
      gTagScript.setAttribute('src', `https://www.googletagmanager.com/gtag/js?id=${mainKey}`)
      document.head.appendChild(gTagScript)

      gTagScript.onload = () => {
        window.dataLayer = window.dataLayer || [];
        function gtag () { dataLayer.push(arguments); }
        gtag('js', new Date());

        tKeys.forEach(tKey => {
          gtag('config', tKey);
        });
      }

    },
  }
}

export default Mixin
