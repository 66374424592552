<template>
    <Layout>
        <div cclass="home-page">
            <section id="slider_area">
                <SliderArea />
            </section>

            <section id="color_selector" class="ptb-100">
                <div class="container">
                    <ColorSelector categorySlug="color-gel"/>
                    <!-- check later aqui va color-gel -->
                </div>
            </section>

            <section id="product_variation_one" class="ptb-100">
                <div class="container">
                    <div class="row">
                        <template v-for="(category, index) in categories">

                        <div v-if="category.photo" :key="index" class="col-lg-3 col-md-3 col-sm-6">
                        <router-link :to="{ path: `/categories/${category.online_store_slug}` }">
                            <div class="product_variation_one_boxed img-zoom-hover">
                                <img
                                    :src="getMediaPath(category.photo)"
                                    alt="img"
                                />
                            </div>
                        </router-link>
                        </div>
                        </template>
                    </div>
                </div>
            </section>

            <!--section
                id="banner_video"
                class="flex-embed-content flex-embed-cover-image"
            >
                <img
                    :src="require('@/assets/img/banners/video.jpeg')"
                    alt="img"
                    class="img-reponsive img-rounded"
                />
            </section-->

            <section class="ptb-100" v-if="company.show_section_links">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-6 col-md-12 col-sm-12 col-12 dismiss-responsive">
                            <img
                                :src="require('@/assets/img/home/unas.jpeg')"
                                alt="img"
                                class="img-reponsive img-rounded"
                            />
                        </div>
                        <div class="col-lg-6 col-md-12 col-sm-12 col-12">
                            <b-list-group class="spacing_list">
                                <b-list-group-item
                                    href="#"
                                    @click="openPdf(1)"
                                    class="flex-column align-items-start"
                                >
                                    <span class="pull-left">
                                        <img
                                            :src="require('@/assets/img/items/listitem1.png')"
                                            style="width: 10%"
                                            class="img-reponsive img-rounded"
                                        />
                                    </span>

                                    Especialistas en Spas
                                </b-list-group-item>
                                <b-list-group-item
                                    href="#"
                                    @click="openPdf(2)"
                                    class="flex-column align-items-start"
                                >
                                    <span class="pull-left">
                                        <img
                                            :src="require('@/assets/img/items/listitem2.png')"
                                            style="width: 10%"
                                            class="img-reponsive img-rounded"
                                        />
                                    </span>

                                    Ver catálogo
                                </b-list-group-item>
                                <b-list-group-item
                                    href="#"
                                    @click="openPdf(3)"
                                    class="flex-column align-items-start"
                                >
                                    <span class="pull-left">
                                        <img
                                            :src="require('@/assets/img/items/listitem3.png')"
                                            style="width: 10%"
                                            class="img-reponsive img-rounded"
                                        />
                                    </span>

                                    Nuestros spas aliados
                                </b-list-group-item>
                                <b-list-group-item
                                    href="#"
                                    @click="openPdf(4)"
                                    class="flex-column align-items-start"
                                >
                                    <span class="pull-left">
                                        <img
                                            :src="require('@/assets/img/items/listitem4.png')"
                                            style="width: 10%"
                                            class="img-reponsive img-rounded"
                                        />
                                    </span>

                                    Formación presencial en Colombia
                                </b-list-group-item>
                                <b-list-group-item
                                    href="#"
                                    @click="openPdf(5)"
                                    class="flex-column align-items-start"
                                >
                                    <span class="pull-left">
                                        <img
                                            :src="require('@/assets/img/items/listitem5.png')"
                                            style="width: 10%"
                                            class="img-reponsive img-rounded"
                                        />
                                    </span>

                                    Nuestras sedes en Colombia
                                </b-list-group-item>
                            </b-list-group>
                        </div>
                    </div>
                </div>
            </section>

            <section v-if="productsNew.length > 0" id="to_days_area" class="ptb-100 slider_arrows_one">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="center_heading">
                                <h2>Productos nuevos</h2>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-lg-12">
                            <swiper
                                class="swiper todays_slider position-relative"
                                :options="swiperOption"
                            >
                                <swiper-slide
                                    v-for="(product, index) in productsNew"
                                    :key="index"
                                >
                                    <ProductCard
                                        :product="product"
                                        :index="index"
                                        @showalert="alert"
                                        @alertseconds="alert"
                                    />
                                </swiper-slide>

                                <div
                                    class="swiper-button-prev swiper-button-white"
                                    slot="button-prev"
                                >
                                </div>
                                <div
                                    class="swiper-button-next swiper-button-white"
                                    slot="button-next"
                                >
                                </div>
                            </swiper>
                        </div>
                    </div>
                </div>
            </section>

            <!-- Add to cart Alert / Notification  -->
        </div>
    </Layout>
</template>

<script>
import Layout from "@/components/layouts/main.vue";
import SliderArea from "@/components/molecules/slider.slick.vue";
import ColorSelector from "@/components/molecules/color.selector.vue";
import ProductCard from "@/components/molecules/product.card.vue";
import LoadingMixin from '@/plugins/Common/LoadingMixin.js'

import { mapState } from "vuex";

export default {
    name: "Home",
    data() {
        return {
            title: "Inicio",
            productsNew: [],
            cartproduct: {},
            compareproduct: {},
            dismissCountDown: 0,
            // Todays Slider options
            swiperOption: {
                slidesPerView: 4,
                slidesPerGroup: 1,
                spaceBetween: 30,
                loop: false,
                mousewheel: false,
                keyboard: {
                    enabled: false,
                },
                navigation: {
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev",
                },
                breakpoints: {
                    1024: {
                        slidesPerView: 4,
                        spaceBetween: 40,
                    },
                    768: {
                        slidesPerView: 3,
                        spaceBetween: 30,
                    },
                    640: {
                        slidesPerView: 2,
                        spaceBetween: 20,
                    },
                    300: {
                        slidesPerView: 1,
                        spaceBetween: 10,
                    },
                },
                autoplay: false,
            },
        };
    },
    computed: {
        ...mapState({
            categories: (state) => state.general.filters.categories,
            company: (state) => state.general.info,
        }),
    },
    mounted() {
        this.getProductsNew()
        // For scroll page top for every Route
        window.scrollTo(0, 0);
    },
    methods: {
        getProductsNew() {
            // if (this.statusIsLoading()) return
            const query = this.$Models.Product.query()
            query.where('online_store_is_new', true)
            query.forPage(1, 10)
            this.productsNew = []
            // this.statusLoading()

            query.get()
                .then((res) => {
                    this.productsNew = res.items;
                })
                .catch((error) => {
                    console.error(error);
                });
        },
        openPdf(type) {
            switch (type) {
                case 1:
                    window.open(
                        this.company.external_links.about,
                        "_blank"
                    );
                    break;
                case 2:
                    window.open(
                        this.company.external_links.catalog,
                        "_blank"
                    );
                    break;
                case 3:
                    window.open(
                        this.company.external_links.about,
                        "_blank"
                    );
                    break;
                case 4:
                    window.open(
                        "https://jemi.so/capacitaciones-presenciales-on",
                        "_blank"
                    );
                    break;
                case 5:
                    window.open("https://jemi.so/departamentoson", "_blank");
                    break;
                default:
                    break;
            }
        },
        alert(item) {
            this.$swal({
                title: "Excelente",
                text: "El producto se ha agregado al carrito",
                icon: "success",
                showCancelButton: true,
                confirmButtonColor: "#EFC437",
                cancelButtonColor: "#000",
                cancelButtonText: "Seguir comprando",
                confirmButtonText: "Ir al carrito",
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$router.push('/cart')
                }
            });
        },
    },
    mixins: [LoadingMixin],
    components: {
        Layout,
        SliderArea,
        ColorSelector,
        ProductCard,
    },
};
</script>
