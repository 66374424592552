import Cart from '@/api/Models/Cart/Cart.js'

export default {
  namespaced: true,
  state: {
    cartIsLoading: false,
    cart: {
      items: [],
      coupon: null,
      totals: {}
    }
  },
  mutations: {
    setLoading (state, value) {
      state.cartIsLoading = value
    },
    setCart (state, cart) {
      if (cart) {
        state.cart = cart
      }
    }
  },
  actions: {
    setLoading (context, value) {
      context.commit('setLoading', value)
    },
    loadCart (context) {
      context.commit('setLoading', true)

      Cart
        .get()
        .then(res => {
          context.commit('setLoading', false)
          if (res.ok) {
            context.commit('setCart', res.cart)
          }
        })
        .catch(() => {
          context.commit('setLoading', false)
        })
    }
  }
}
