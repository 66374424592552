
export default class Builder {
  constructor (connection, model) {
    this.connection = connection

    this.stack = {}

    this._model = null

    if (model) this._setModel(model)
  }

  _call (args) {
    if (args.f in this.stack) {
      this.stack[args.f][args.o] = args.v
    } else {
      this.stack[args.f] = { [args.o]: args.v }
    }
    if (args.c) {
      this.stack[args.f].c = args.c
    }
    return this
  }

  getOName (operator) {
    const oNames = {
      '=': 'eq',
      '!=': 'notEq',
      '>': 'gt',
      '<': 'lt',
      '>=': 'gte',
      '<=': 'lte',
      like: 'like'
    }

    if (Object.keys(oNames).indexOf(operator) > -1) {
      return oNames[operator]
    }

    throw new Error('invalid operator')
  }

  where (...args) {
    const data = {}
    if (args.length === 3) {
      data.f = args[0]
      data.o = this.getOName(args[1])
      data.v = args[2]
    } else if (args.length === 2) {
      data.f = args[0]
      data.o = 'eq'
      data.v = args[1]
    } else {
      throw new Error('Invalid data to where condition')
    }
    return this._call(data)
  }

  whereDate (...args) {
    const data = {
      c: 'date'
    }
    if (args.length === 3) {
      data.f = args[0]
      data.o = this.getOName(args[1])
      data.v = args[2]
    } else if (args.length === 2) {
      data.f = args[0]
      data.o = 'eq'
      data.v = args[1]
    } else {
      throw new Error('Invalid data to where condition')
    }
    return this._call(data)
  }

  orderBy (order) {
    if (Array.isArray(this.stack._orderBy)) {
      this.stack._orderBy.push(order)
    } else {
      this.stack._orderBy = [order]
    }
    return this
  }

  limit (limit) {
    // return this._call([limit])
    this.stack._limit = limit
    return this
  }

  forPage (page, perPage) {
    // return this._call(forPage)
    this.stack._pagination = {
      page,
      perPage
    }
    return this
  }

  with (relations) {
    this.stack._with = relations
    return this
  }

  sortByDefault () {
    const orderBy = this._model.constructor.orderByDefault
    if (orderBy) {
      if (typeof orderBy === 'string') {
        this.orderBy(orderBy)
      } else if (Array.isArray(orderBy)) {
        orderBy.forEach(value => {
          this.orderBy(value)
        })
      }
    }
    return this
  }

  find (id, columns) {
    return this
      .connection
      .read(id)
      .then(result => result.data.result ? this._model.newInstance(result.data.result, true) : null)
  }

  findOrFail (id, columns) {
    return this.find(id, columns).then(throwIfNotFound)
  }

  first (columns) {
    return this.limit(1).get(columns).then(unwrapFirst)
  }

  firstOrFail (columns) {
    return this.first(columns).then(throwIfNotFound)
  }

  get (columns) {
    if (columns) {
      this.select(columns)
    }

    return this
      .connection
      .read(this.stack)
      .then(results => {
        return {
          items: this._model.hydrate(results.data.result.items),
          count: results.data.result.count
        }
      }
      )
  }

  _getModel () {
    return this._model
  }

  _setModel (model) {
    this._model = model;

    (model.constructor.scopes || []).forEach(name => {
      this[name] = function (...args) {
        this._call(name, args)
        // this.scope(name, args)
        return this
      }
    })
  }
}

class ModelNotFoundException extends Error {
  constructor (...args) {
    super(...args)
    this.name = 'ModelNotFoundException'
    /*
    Error.captureStackTrace(this, ModelNotFoundException)
    */
  }
}

function unwrapFirst (results) {
  if (results.items) {
    return results.items[0] ? results.items[0] : null
  }
  return null
}

function throwIfNotFound (result) {
  if (result === null) {
    throw new ModelNotFoundException('No se encontró el Elemento')
  }

  return result
}
