import { RawConnection } from '@/api/ORM/connection/index'

export default {
  namespaced: true,
  state: {
    pageIsLoading: false,
    info: {}, // check later
    filters: {
      categories: []
    },
    snackbar: {
      state: false,
      color: '',
      message: ''
    }
  },
  mutations: {
    setDepartaments (state, departaments) {
      state.departaments = departaments
    },
    setMunicipalities (state, municipalities) {
      state.municipalities = municipalities
    },
    setFilters (state, res) {
      state.filters.categories = res.categories.items
    },
    setLoading (state, value) {
      state.pageIsLoading = value
    },
    setInfo (state, info) {
      state.info = info
    }
  },
  actions: {
    loadInfo (context) {
      const connection = new RawConnection()

      const url = '/api/v1/company/info'

      return connection.post(url)
        .then(result => result.data.data.result)
        .then(res => {
          context.commit('setInfo', res)
          return res
        })
    },
    loadDepartaments (context) {
      const connection = new RawConnection()

      const url = '/api/v1/company/departaments'

      return connection.post(url)
        .then(result => result.data.data.result)
        .then(res => {
          context.commit('setDepartaments', res)
          return res
        })
    },
    loadMunicipalities (context) {
      const connection = new RawConnection()

      const url = '/api/v1/company/municipalities'

      return connection.post(url)
        .then(result => result.data.data.result)
        .then(res => {
          context.commit('setMunicipalities', res)
          return res
        })
    },
    loadFilters (context) {
      const connection = new RawConnection()

      const url = '/api/v1/products/filters'

      return connection.get(url)
        .then(result => result.data.data.result)
        .then(res => {
          context.commit('setFilters', res)
          return res
        })
    },
    setLoading (context, value) {
      context.commit('setLoading', value)
    }
  },
  getters: {
    allInfo: (state) => {
      return state.info
    },
    categories: (state) => {
      return state.filters.categories
    }
  }
}
