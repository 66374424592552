import Service from '@/api/ORM/connection/axios'

export default class Rest {
  constructor (endpoint) {
    this.endpoint = endpoint
    this.service = Service
  }

  read (idOrQuery) {
    const url = `${this.endpoint}/search`
    return this.service
      .post(url, idOrQuery)
      .then(response => response.data)
  }
}
