export const dictionary = {
    es: {
        global: {

        },
        menu: {
            home: "Inicio",
            aboutus: "Nosotros",
            categories: "Categorías",
            training: "Formación"
        },
        search: {
            labelmenu: "Escriba aquí la(s) palabra(s) clave",
            button: "Buscar",
        },
        footer: {
            information: "Información",
            menu: {
                home: "Inicio",
                aboutus: "Nosotros",
            },
        },
    },
};
