import { RawConnection } from '@/api/ORM/connection/index'

class Contact {
  static send (body) {
    const connection = new RawConnection()
    const url = '/api/v1/contact/send'
    return connection.post(url, body)
      .then(result => result.data)
  }
}

export default Contact
