<template>
    <div class="col-lg-3">
        <div class="shop_sidebar_wrapper" v-if="!haveError">
            <div class="shop_Search">
                <form @submit.prevent="() => {}">
                    <input
                        v-on:input="debounceInput"
                        type="text"
                        id="shop_search_value"
                        class="form-control border-1"
                        placeholder="Buscar..."
                    />
                    <button>
                        <img :src="require('@/assets/img/svg/search.svg')" alt="img"/>
                    </button>
                </form>
            </div>
            <div v-show="showFilters">
            <br>

            <select
                class="form-control"
                v-model="sortBy"
                id="variant_product"
                @change="handlerSort"
            >
                <option value="name ASC">de A a Z</option>
                <option value="name DESC">de Z a A</option>
            </select>

            <div class="shop_sidebar_boxed">
                <h4>Categorías</h4>
                <form>
                    <label class="custom_boxed">
                        Todos
                        <input
                            type="radio"
                            name="radio"
                            :checked="categorySelected === null"
                            @change="handlerCategory(null)"
                        />
                        <span class="checkmark"></span>
                    </label>
                    <label v-for="item in categories" class="custom_boxed">
                        {{cleanText(item.name)}}
                        <input
                            type="radio"
                            name="radio"
                            :checked="categorySelected === item.id"
                            @change="handlerCategory(item)"
                        />
                        <span class="checkmark"></span>
                    </label>
                </form>
            </div>

            <div class="shop_sidebar_boxed">
                <h4>Precio</h4>
                <div class="price_filter">
                    <!--check later-->
                    <vue-slider
                        v-model="value"
                        :interval="1000"
                        :min="0"
                        :max="1000000"
                        ref="slider"
                        @change="debouncePrice"
                    >
                    </vue-slider>
                    <div class="price-slider-amount mt-3 text-capitalize">
                        Precio: {{ value[0] | currencyFormat }} - {{ value[1] | currencyFormat }}
                    </div>
                </div>
            </div>
            </div>

            <div class="text-center mt-2">
                <button v-if="showFilters" @click="setShowFilter(false)" class="theme-btn-one btn-black-overlay btn_sm">Ocultar Filtros</button>
                <button v-else @click="setShowFilter(true)" class="theme-btn-one btn-black-overlay btn_sm">Mostrar Filtros</button>
            </div>
        </div>
        <b-alert v-model="showDismissibleAlert" variant="danger" dismissible>{{ messageRequest }}</b-alert>
    </div>
</template>

<script>
import { mapState } from "vuex";
import _ from "lodash";
export default {
    name: "ShopSidebar",
    data() {
        return {
            showFilters: true,
            sortBy: 'name ASC',
            // For Range Slider Value
            tempCategorySlug: null,
            value: [0, 0], // check later
            categorySelected: null,
            showDismissibleAlert: false,
            messageRequest: "",
            haveError: false
        };
    },
    computed: {
        ...mapState({
            categories: (state) => state.general.filters.categories,
        }),
    },
    methods: {
        setShowFilter (value) {
            if (value) {
                this.showFilters = true
            } else {
                this.showFilters = false
            }
        },
        setShowFilterMobile (value) {
            var width = (window.innerWidth > 0) ? window.innerWidth : screen.width;

            if (width <= 768) {
                if (value) {
                    this.showFilters = true
                } else {
                    this.showFilters = false
                }
            }
        },
        debounceInput: _.debounce(function (e) {
            const value = e.target.value;
            this.handlerSearch(value);
        }, 500),
        debouncePrice: _.debounce(function (e) {
            const value = this.value;
            this.handlerPrice(value);
        }, 500),
        setQValue(value){
            document.getElementById('shop_search_value').value= value;
        },
        setCategoryBySlug(categorySlug){
            this.tempCategorySlug = categorySlug

            if (this.tempCategorySlug) {
                const category = this.categories.find(category => category.online_store_slug === this.tempCategorySlug)
                if (category) {
                    this.categorySelected = category.id
                    this.tempCategorySlug = null
                }
            }
        },
        handlerCategory(category){
            if (category === null){
                this.categorySelected = null
                this.$emit("changeCategory", {type: 'category', value: null, 'slug': null});
                this.setShowFilterMobile(false)
            } else {
                var inputSearch = document.getElementById("shop_search_value");
                inputSearch.value = "";

                this.categorySelected = category.id
                this.$emit("changeCategory", {type: 'category', value: category.id, 'slug': category.online_store_slug});
                this.setShowFilterMobile(false)
            }
        },
        handlerSearch(text){
            this.categorySelected = null

            this.$emit("changeCategory", {type: 'search', value: text});
            this.setShowFilterMobile(false)
        },
        handlerPrice(price){
            this.$emit("changeCategory", {type: 'price', value: price});
            this.setShowFilterMobile(false)
        },
        handlerSort(){
            this.$emit("changeCategory", {type: 'sort', value: this.sortBy});
            this.setShowFilterMobile(false)
        },
        getImageUrl(path) {
            return null; // require("@/assets/img/product-image/" + path); // check later
        },
        cleanText(text) {
            const txtLowerCase = text.toString().toLowerCase();
            return txtLowerCase.charAt(0).toUpperCase() + txtLowerCase.slice(1);
        },
        // For Shop Left Shorting and Up Shorting
    },
    watch: {
        categories (nVal, oVal) {
            if (this.tempCategorySlug) {
                const category = this.categories.find(category => category.online_store_slug === this.tempCategorySlug)
                if (category) {
                    this.categorySelected = category.id
                    this.setShowFilterMobile(false)
                }
                this.tempCategorySlug = null
            }
        }
    },
};
</script>
