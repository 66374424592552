
import Product from '@/api/Models/Product/Product'
import Customer from '@/api/Models/Customer/Customer'
import Cart from '@/api/Models/Cart/Cart'
import Contact from '@/api/Models/Contact/Contact'
import GiftCard from '@/api/Models/GiftCard/GiftCard'
import Auth from '@/api/Models/User/Auth'

export default {
  Product,
  Customer,
  Cart,
  Contact,
  GiftCard,
  Auth
}
