<template>
    <div>
        <!--div class="banner_top">
            <img src="@/assets/img/banners/top.png" />
        </div-->
        <!-- Start Header Area -->
        <header class="header-section d-none d-xl-block">
            <div class="header-wrapper">
                <div
                    id="header"
                    class="header-bottom header-bottom-color--golden section-fluid sticky-header sticky-color--golden"
                >
                    <div class="container">
                        <div class="row">
                            <div
                                class="col-12 d-flex align-items-center justify-content-between"
                            >
                                <!-- Start Header Logo -->
                                <div class="header-logo">
                                    <div class="logo" style="padding: 5px">
                                        <router-link to="/"><img :src="logo_media" alt="logo"/></router-link>
                                    </div>
                                </div>
                                <!-- End Header Logo -->

                                <!-- Start Header Main Menu -->
                                <div
                                    class="main-menu menu-color--black menu-hover-color--white"
                                >
                                    <nav>
                                        <ul>
                                            <li class="has-dropdown">
                                                <a href="#">
                                                    {{$t("menu.categories")}}
                                                    <i class="fa fa-angle-down"></i>
                                                </a>
                                                <!-- Sub Menu -->
                                                <ul class="sub-menu">
                                                    <li v-for="item in categories">
                                                    <!--:to="{path: `/categories/${item.online_store_slug}`,}"-->
                                                        <a href="#" @click="goToCategory(item)">{{cleanText(item.name)}}</a>
                                                    </li>
                                                </ul>
                                            </li>
                                            <li class="has-dropdown" v-if="training.length > 0">
                                                <a href="#">
                                                    {{$t("menu.training")}}
                                                    <i class="fa fa-angle-down"></i>
                                                </a>
                                                <!-- Sub Menu -->
                                                <ul class="sub-menu">
                                                    <li v-for="item in training">
                                                    <!--:to="{path: `/training/${item.online_store_slug}`,}"-->
                                                        <a :href="item.link" target="_blank">{{item.name}}</a>
                                                    </li>
                                                </ul>
                                            </li>
                                            <li class="has-dropdown">
                                                <a href="#" @click="openCategoryColorGel()">
                                                    Color Gel
                                                    <!--iclass="fa fa-angle-down"></i-->
                                                </a>
                                                <!-- Sub Menu -->
                                                <!--ul class="sub-menu">
                                                    <li></li>
                                                </ul-->
                                            </li>
                                            <li class="has-dropdown" v-if="company.show_menu_about">
                                                <a href="#" @click="openAbout()">Sobre Nosotros</a>
                                            </li>
                                            <li class="has-dropdown" v-if="company.show_menu_catalog">
                                                <a href="#" @click="openCatalog()">Catálogo</a>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                                <!-- End Header Main Menu Start -->

                                <!-- Start Header Action Link -->
                                <ul
                                    class="header-action-link action-color--black action-hover-color--golden"
                                >
                                    <li>
                                        <a
                                            v-b-toggle.offcanvas-add-cart
                                            class="offcanvas-toggle"
                                        >
                                            <i class="fas fa-shopping-bag"></i>
                                            <span class="item-count">{{cart.items.length}}</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            v-b-toggle.search_sidebar
                                            class="search_width offcanvas-toggle"
                                        >
                                            <img src="@/assets/img/svg/search.svg" alt="img"/>
                                        </a>
                                    </li>
                                </ul>
                                <!-- End Header Action Link -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
        <!-- Start Header Area -->

        <!-- Start Mobile Header -->
        <div
            id="mobile_header"
            class="mobile-header sticky-header sticky-color--white mobile-header-bg-color--white section-fluid d-lg-block d-xl-none"
        >
            <div class="container">
                <div class="row">
                    <div
                        class="col-12 d-flex align-items-center justify-content-between"
                    >
                        <!-- Start Mobile Left Side -->
                        <div class="mobile-header-left">
                            <ul class="mobile-menu-logo">
                                <li>
                                    <router-link to="/">
                                        <div class="logo">
                                            <img :src="require('@/assets/img/logo.png')" alt="logo"/>
                                        </div>
                                    </router-link>
                                </li>
                            </ul>
                        </div>
                        <!-- End Mobile Left Side -->

                        <!-- Start Mobile Right Side -->
                        <div class="mobile-right-side">
                            <ul
                                class="header-action-link action-color--black action-hover-color--golden"
                            >
                                <li>
                                    <a v-b-toggle.search_sidebar class="search_width offcanvas-toggle">
                                        <img src="@/assets/img/svg/search.svg" alt="img"/>
                                    </a>
                                </li>
                                <li>
                                    <a v-b-toggle.offcanvas-add-cart class="offcanvas-toggle">
                                        <i class="fas fa-shopping-bag"></i>
                                        <span class="item-count">{{cart.items.length}}</span>
                                    </a>
                                </li>
                                <li>
                                    <a v-b-toggle.offcanvas-about class="offacnvas offside-about offcanvas-toggle"><i class="fas fa-bars"></i></a>
                                </li>
                            </ul>
                        </div>
                        <!-- End Mobile Right Side -->
                    </div>
                </div>
            </div>
        </div>
        <!-- End Mobile Header -->

        <b-sidebar
            id="offcanvas-about"
            class="offcanvas-mobile-about-section"
            backdrop
            right
        >
            <!-- Start Mobile Menu Navbar Wrap -->
            <div class="mobile-menu-navbar-wrap">
                <!-- Start Mobile Menu Nav -->
                <div class="offcanvas-menu">
                    <nav>
                        <ul>
                            <li class="has-dropdown">
                                <a href="#" v-b-toggle.collapse-1>
                                    {{ $t("menu.categories") }}
                                    <i class="fa fa-angle-down"></i>
                                </a>
                                <!-- Sub Menu -->
                                <b-collapse id="collapse-1" class="mt-2">
                                    <ul>
                                        <li v-for="item in categories">
                                            <!--:to="{path: `/categories/${item.online_store_slug}`,}"-->
                                            <a href="#" @click="goToCategory(item)">{{ cleanText(item.name) }}</a>
                                        </li>
                                    </ul>
                                </b-collapse>
                            </li>
                            <li class="has-dropdown" v-if="training.length > 0">
                                <a href="#" v-b-toggle.collapse-2>
                                    {{ $t("menu.training") }}
                                    <i class="fa fa-angle-down"></i>
                                </a>
                                <!-- Sub Menu -->
                                <b-collapse id="collapse-2" class="mt-2">
                                    <ul>
                                        <li v-for="item in training">
                                            <!--:to="{path: `/categories/${item.online_store_slug}`,}"-->
                                            <a :href="item.link" target="_blank">{{ item.name }}</a>
                                        </li>
                                    </ul>
                                </b-collapse>
                            </li>
                            <li class="has-dropdown">
                                <a href="#" @click="openCategoryColorGel()">
                                    Color Gel
                                    <!--i class="fa fa-angle-down"></i-->
                                </a>
                                <!-- Sub Menu -->
                                <!--ul class="sub-menu">
                                    <li></li>
                                </ul-->
                            </li>
                            <li class="has-dropdown" v-if="company.show_menu_about">
                                <a href="#" @click="openAbout()">Sobre Nosotros</a>
                            </li>
                            <li class="has-dropdown" v-if="company.show_menu_catalog">
                                <a href="#" @click="openCatalog()">Catálogo</a>
                            </li>
                        </ul>
                    </nav>
                </div>
                <!-- End Mobile Menu Nav -->
            </div>
            <!-- End Mobile Menu Navbar Wrap -->
        </b-sidebar>

        <!-- Start Offcanvas Addcart Section -->
        <b-sidebar
            id="offcanvas-add-cart"
            class="offcanvas-add-cart-section"
            backdrop
            right
        >
            <div class="offcanvas-add-cart-wrapper">
                <h4 class="offcanvas-title">Carrito de compras</h4>
                <div v-if="cart.items.length">
                    <ul class="offcanvas-cart">
                        <li
                            class="offcanvas-cart-item-single"
                            v-for="(item, index) in cart.items"
                            :key="index"
                        >
                            <div class="offcanvas-cart-item-block">
                                <div
                                    @click="goToProduct(item.product)"
                                    style="cursor: pointer;"
                                    :to="{ path: '/products/' + item.product.online_store_slug }"
                                    class="offcanvas-cart-item-image-link"
                                >
                                    <img
                                        :src="getMediaPath(item.product.photo)"
                                        alt="img"
                                        class="offcanvas-cart-image"
                                    />
                                </div>
                                <div class="offcanvas-cart-item-content">
                                    <div
                                        @click="goToProduct(item.product)"
                                        style="cursor: pointer;"
                                        :to="{ path: '/products/' + item.product.online_store_slug }"
                                        class="offcanvas-cart-item-link"
                                    >
                                        {{ item.product.name }}
                                    </div>
                                    <div class="offcanvas-cart-item-details">
                                        <span class="offcanvas-cart-item-details-price">{{ item.price | currencyFormat }}</span>
                                        <br/>
                                        <b-form-spinbutton
                                            id="sb-inline"
                                            min="1"
                                            max="100"
                                            v-model="item.quantity"
                                            inline
                                            class="border-1"
                                            :disabled="status.loading"
                                            @change="updateItemCart(item)"
                                        ></b-form-spinbutton>
                                    </div>
                                </div>
                            </div>
                            <div class="offcanvas-cart-item-delete text-right">
                                <button
                                    @click="removeCartItem(item)"
                                    class="offcanvas-cart-item-delete bg-transparent remove-btn"
                                >
                                    <i class="far fa-trash-alt"></i>
                                </button>
                            </div>
                        </li>
                    </ul>

                    <div v-if="company.shipping_method === 'VARIABLE_SHIPPING'" class="offcanvas-cart-total-price">
                    </div>
                    <div v-else class="offcanvas-cart-total-price">
                        <span class="offcanvas-cart-total-price-text">Valor envio:</span>
                        <span class="offcanvas-cart-total-price-value">{{ cart.totals.shipping | currencyFormat }}</span>
                    </div>
                    <div class="offcanvas-cart-total-price" v-if="cart.totals.discount > 0">
                        <span class="offcanvas-cart-total-price-text">Descuento:</span>
                        <span class="offcanvas-cart-total-price-value">{{ cart.totals.discount | currencyFormat }}</span>
                    </div>
                    <div class="offcanvas-cart-total-price">
                        <span class="offcanvas-cart-total-price-text">Total:</span>
                        <span class="offcanvas-cart-total-price-value">{{ cart.totals.total | currencyFormat }}</span>
                    </div>

                    <ul class="offcanvas-cart-action-button">
                        <li>
                            <router-link to="/cart" class="theme-btn-one btn-black-overlay btn_md">Ver Carrito</router-link>
                        </li>
                        <li>
                            <router-link to="/cart/summary" class="theme-btn-one btn-black-overlay btn_md">Ir a pagar</router-link>
                        </li>
                    </ul>
                </div>

                <ul v-else class="offcanvas-cart">
                    <li>Tu carrito esta vacío!</li>
                </ul>
            </div>
        </b-sidebar>
        <!-- End  Offcanvas Addcart Section -->

        <!-- Start Offcanvas Search Bar Section -->
        <b-sidebar id="search_sidebar" backdrop>
            <div
                class="px-3 py-2 search-sidebar-content-wrap d-flex justify-content-center align-content-center w-100 h-100"
            >
                <form
                    @submit.prevent="() => {}"
                    class="d-flex flex-column justify-content-center product-search-form"
                >
                    <input
                        type="search"
                        :placeholder="$t('search.labelmenu')"
                        v-model="searchString"
                        v-on:input="debounceInput"
                        v-on:keyup.enter="onEnter"
                    />

                    <!-- Show search product here -->
                    <ul v-if="searchItems.length" class="search-results-wrap">
                        <li
                            v-for="(product, index) in searchItems"
                            :key="index"
                        >
                            <div class="offcanvas-cart-item-block mb-3">
                                <div
                                    :to="{ path: '/products/' + product.online_store_slug }"
                                    @click="goToProduct(product)"
                                    style="cursor: pointer;"
                                    class="offcanvas-cart-item-image-link"
                                >
                                    <img
                                        :src="getMediaPath(product.photo)"
                                        alt="img"
                                        class="offcanvas-cart-image"
                                    />
                                </div>
                                <div class="offcanvas-cart-item-content">
                                    <div
                                        @click="goToProduct(product)"
                                        style="cursor: pointer;"
                                        :to="{path: '/products/' + product.online_store_slug,}"
                                        class="offcanvas-cart-item-link text-white"
                                    >
                                        {{ product.name }}
                                    </div>
                                    <div class="offcanvas-cart-item-details" v-if="!product.can_variant">
                                        <span class="offcanvas-cart-item-details-price text-white">{{product.price.value_price | currencyFormat}}</span>
                                        <span v-if="product.price.special_price" style="text-decoration: line-through;" class="offcanvas-cart-item-details-price text-secondary">{{product.price.retail_price | currencyFormat}}</span>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <!-- Show search product here -->
                </form>
            </div>
        </b-sidebar>
        <!-- End Offcanvas Search Bar Section -->
    </div>
</template>

<script>
import { mapState } from "vuex";
import _ from "lodash";
import LoadingMixin from '@/plugins/Common/LoadingMixin.js'
import RecaptchaMixin from '@/plugins/Common/RecaptchaMixin.js'
import GTagMixin from '@/plugins/Common/GTagMixin.js'

export default {
    data() {
        return {
            // Mobile Menu Item Data
            searchItems: [], // check later
            training: [],
            products: [],
            category: [],
            cartproduct: {},
            searchString: "",
            logo_media:
                this.company && this.company.store_logo
                    ? this.getMediaPath(this.company.store_logo)
                    : require("@/assets/img/logo.png"),
        };
    },
    mounted: function () {
        // Menu Js
        this.$nextTick(function () {
            window.onscroll = function () { styck(); };
            var header = document.getElementById("header");
            var mobile_header = document.getElementById("mobile_header");
            var sticky = header.offsetTop;
            function styck() {
                if (window.pageYOffset > sticky) {
                    header.classList.add("sticky");
                    mobile_header.classList.add("sticky");
                } else {
                    header.classList.remove("sticky");
                    mobile_header.classList.remove("sticky");
                }
            }
        });
        // Menu End
        // this.productsArray()
        if (this.company) {
            if (this.company.menu_links) {
                this.training = this.company.menu_links
            }
        }
    },
    computed: {
        ...mapState({
            categories: (state) => state.general.filters.categories,
            cart: state => state.cart.cart,
            company: (state) => state.general.info,
        }),
        count() {
            return this.$store.state.count;
        },
    },
    methods: {
        debounceInput: _.debounce(function (e) {
            this.searchString = e.target.value;
            this.searchProduct();
        }, 500),
        onEnter() {
            if (this.searchString) {
                if (['routes.name.products', 'routes.name.categodyDetail'].indexOf(this.$route.name) >= 0) {
                    window.location.assign('/products?q=' + encodeURIComponent(this.searchString))
                } else {
                    this.$router.push('/products?q=' + encodeURIComponent(this.searchString));
                }
            }
        },
        cleanText(text) {
            const txtLowerCase = text.toString().toLowerCase();
            return txtLowerCase.charAt(0).toUpperCase() + txtLowerCase.slice(1);
        },
        // Image Url
        goToProduct(product) {
            if (['routes.name.productDetail'].indexOf(this.$route.name) >= 0) {
                window.location.assign('/products/' + product.online_store_slug)
            } else {
                this.$router.push('/products/' + product.online_store_slug);
            }
        },
        goToCategory(category) {
            if (['routes.name.products', 'routes.name.categodyDetail'].indexOf(this.$route.name) >= 0) {
                window.location.assign('/categories/' + category.online_store_slug)
            } else {
                this.$router.push('/categories/' + category.online_store_slug);
            }
        },
        removeCartItem (product) {
            // if (this.readonly) { return null }
            if (this.statusIsLoading()) return

            this.statusLoading()

            this.$Models.Cart
                .remove(product.id)
                .then(res => {
                this.statusLoadingComplete()

                if (res.ok) {
                    // this.showSuccessMessage(res.message)
                    this.$store.commit('cart/setCart', res.cart)
                } else {
                    this.$store.commit('cart/setCart', res.cart)
                    this.showError(res.message)
                }
                })
                .catch(err => {
                    this.statusLoadingFail()
                    this.showError(err)
                })
        },
        updateItemCart (product) {
            if (this.statusIsLoading()) return

            this.statusLoading()

            this.$Models.Cart
                .updateItem({
                    id: product.id,
                    quantity: product.quantity
                })
                .then(res => {
                    this.statusLoadingComplete()

                    if (res.ok) {
                        // this.showSuccessMessage(res.message)
                        this.$store.commit('cart/setCart', res.cart)
                    } else {
                        this.$store.commit('cart/setCart', res.cart)
                        this.showError(res.message)
                    }
                })
                .catch(err => {
                    this.statusLoadingFail()
                    this.showError(err)
                })
        },
        // For Delete/Remove wishlist Item
        searchProduct () {
            if (this.statusIsLoading()) return

            this.searchItems = []

            const query = this.$Models.Product.query()

            const qValue = this.searchString
            if (qValue !== null && qValue !== undefined) {
                query.where('q', qValue)
            }

            query.forPage(1)

            this.statusLoading()
            query.get()
                .then(res => {
                    return res
                })
                .then(res => {
                    this.searchItems = res.items
                    this.statusLoadingComplete()
                })
                .catch((err) => {
                    this.statusLoadingFail()
                    this.showError(err)
                })
        },
        openCategoryColorGel() {
            this.goToCategory({ online_store_slug: 'color-gel' })
        },
        openAbout() {
            window.open(
                this.company.external_links.about,
                "_blank"
            );
        },
        openCatalog() {
            window.open(
                this.company.external_links.catalog,
                "_blank"
            );
        },
    },
    watch: {
        company (nVal, oVal) {
            this.wathInfoSiteKey(nVal, oVal)
            this.wathInfoSiteGTagsKeyGTag(nVal, oVal)

            if (this.company.menu_links) {
                this.training = this.company.menu_links
            }
        }
    },
    mixins: [LoadingMixin, RecaptchaMixin, GTagMixin],
};
</script>

<style>
/* Mobile Menu Multi Dropdown Items Start */
.v-sidebar-menu .vsm--toggle-btn {
    display: none;
}

.v-sidebar-menu {
    position: relative;
    background-color: transparent;
}

.v-sidebar-menu.vsm_expanded .vsm--item_open .vsm--link_level-1 {
    background-color: var(--main-theme-color);
}

.v-sidebar-menu .vsm--link_level-1.vsm--link_exact-active,
.v-sidebar-menu .vsm--link_level-1.vsm--link_active {
    -webkit-box-shadow: 3px 0px 0px 0px var(--main-theme-color) inset;
    box-shadow: 3px 0px 0px 0px var(--main-theme-color) inset;
}

/* Mobile Menu Multi Dropdown Items End */
</style>
