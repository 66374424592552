<template>
    <Layout>
        <div>
            <!-- Banner Area -->
            <section id="common_banner_one">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="common_banner_text">
                                <h2>{{ this.title }}</h2>
                                <b-breadcrumb :items="breadcrumbItems" class="bg-transparent"></b-breadcrumb>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <!-- Cart-Area -->
            <section id="cart_area_one" class="ptb-100">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                            <div v-if="cart.items.length">
                                <ul class="offcanvas-cart">
                                    <li
                                        class="offcanvas-cart-item-single"
                                        v-for="(item, index) in cart.items"
                                        :key="index"
                                    >
                                        <div class="offcanvas-cart-item-block">
                                            <router-link
                                                :to="{path: '/products/' + item.product.online_store_slug}"
                                                class="offcanvas-cart-item-image-link"
                                            >
                                                <img
                                                    :src="getMediaPath(item.product.photo)"
                                                    alt="img"
                                                    class="offcanvas-cart-image"
                                                />
                                            </router-link>
                                            <div
                                                class="offcanvas-cart-item-content"
                                            >
                                                <router-link
                                                    :to="{path:'/products/' +item.product.online_store_slug}"
                                                    class="offcanvas-cart-item-link"
                                                >
                                                    {{item.product.name}}
                                                </router-link>
                                                <div
                                                    class="offcanvas-cart-item-details"
                                                >
                                                    <span class="offcanvas-cart-item-details-price">{{ item.price | currencyFormat }}</span>
                                                    <br />
                                                    <b-form-spinbutton
                                                        id="sb-inline"
                                                        min="1"
                                                        max="100"
                                                        v-model="item.quantity"
                                                        :disabled="status.loading"
                                                        inline
                                                        class="border-1"
                                                        @change="updateItemCart(item)"
                                                    ></b-form-spinbutton>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            class="offcanvas-cart-item-delete text-right"
                                        >
                                            <button
                                                @click="removeCartItem(item)"
                                                class="offcanvas-cart-item-delete bg-transparent remove-btn"
                                            >
                                                <i class="far fa-trash-alt"></i>
                                            </button>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                            <div
                                class="coupon_code right"
                                data-aos="fade-up"
                                data-aos-delay="400"
                                style="margin-top: 5px"
                            >
                                <h3 style="color: white !important">
                                    Resumen de compra
                                </h3>
                                <div class="coupon_inner">
                                    <div class="cart_subtotal">
                                        <p>Subtotal</p>
                                        <p class="cart_amount">{{cart.totals.total - cart.totals.shipping + cart.totals.discount | currencyFormat}}</p>
                                    </div>
                                    <div v-if="company.shipping_method === 'VARIABLE_SHIPPING'">
                                    </div>
                                    <div v-else class="cart_subtotal">
                                        <p>Envío</p>
                                        <p class="cart_amount">{{cart.totals.shipping | currencyFormat}}</p>
                                    </div>
                                    <div v-if="cart.totals.discount > 0" class="cart_subtotal">
                                        <p>Descuento</p>
                                        <p class="cart_amount">{{cart.totals.discount | currencyFormat}}</p>
                                    </div>
                                    <div class="cart_subtotal">
                                        <p>Total</p>
                                        <p class="cart_amount">
                                            {{cart.totals.total | currencyFormat}}
                                        </p>
                                    </div>
                                    <div class="checkout_btn">
                                        <router-link to="/cart/summary" class="theme-btn-one btn-black-overlay btn_sm">Ir a pagar</router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </Layout>
</template>

<script>
import { mapGetters } from "vuex";
import { mapState } from "vuex";
import Layout from '@/components/layouts/main.vue';
import LoadingMixin from '@/plugins/Common/LoadingMixin.js'

export default {
    name: "Cart",
    data() {
        return {
            title: "Carrito",
            // Breadcrumb Items Data
            breadcrumbItems: [
                {
                    text: "Inicio",
                    to: "/",
                },
                {
                    text: "Carrito",
                    to: "/cart",
                },
            ],
            // Product Quanity Increment/ Decrement Data
            quantity: 1,
        };
    },
    components: {
        Layout
    },
    computed: {
        ...mapState({
            cart: state => state.cart.cart,
            company: (state) => state.general.info
        }),
    },
    mounted() {
        // For scroll page top for every Route
        window.scrollTo(0, 0);
    },
    methods: {
        removeCartItem (product) {
            // if (this.readonly) { return null }
            if (this.statusIsLoading()) return

            this.statusLoading()

            this.$Models.Cart
                .remove(product.id)
                .then(res => {
                this.statusLoadingComplete()

                if (res.ok) {
                    // this.showSuccessMessage(res.message)
                    this.$store.commit('cart/setCart', res.cart)
                } else {
                    this.$store.commit('cart/setCart', res.cart)
                    this.showError(res.message)
                }
                })
                .catch(err => {
                    this.statusLoadingFail()
                    this.showError(err)
                })
        },
        updateItemCart (product) {
            if (this.statusIsLoading()) return

            this.statusLoading()

            this.$Models.Cart
                .updateItem({
                    id: product.id,
                    quantity: product.quantity
                })
                .then(res => {
                    this.statusLoadingComplete()

                    if (res.ok) {
                        // this.showSuccessMessage(res.message)
                        this.$store.commit('cart/setCart', res.cart)
                    } else {

                        this.$store.commit('cart/setCart', res.cart)
                        this.showError(res.message)
                    }
                })
                .catch(err => {
                    this.statusLoadingFail()
                    this.showError(err)
                });
        },
    },
    mixins: [LoadingMixin],
    // Page head() Title, description for SEO
    head() {
        return {
            title: this.title,
            meta: [
                {
                    hid: "description",
                    name: "description",
                    content: "Cart page - Organic Nails Ecommerce Vue js, Nuxt js Template",
                },
            ],
        };
    },
};
</script>
