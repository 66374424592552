<template>
    <div id="app">
        <router-view />
    </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex';

export default {
    name: "App",
    beforeCreate() {
        this.$store.dispatch('cart/loadCart')
        this.$store.dispatch('general/loadInfo')
        this.$store.dispatch('general/loadFilters')
        .then(res => {
            // this.changeTitle(res.name)
            // this.changeFavicon(this.$utilities.publicPath(res.store_logo))
        })

    },
    mounted: function () {

    },
    computed: {
        ...mapGetters({
            company: "general/allInfo",
        }),
    },
    methods: {
    },
    metaInfo() {
        return {
            title: this.company.business_name ? this.company.business_name : 'Organic Nails',
            titleTemplate: `%s - ${this.company.description ? this.company.description : 'Organic Nails tienda oficial'}`,
            htmlAttrs: {
                lang: "es",
            },
            meta: [
                { charset: "utf-8" },
                {
                    name: "viewport",
                    content: "width=device-width, initial-scale=1",
                },
                {
                    hid: "description",
                    name: "description",
                    content: this.company.description ? this.company.description : 'Organic Nails tienda oficial',
                },
            ],
            link: [
                { rel: "icon", type: "image/x-icon", href: "/favicon.ico" },
                {
                    rel: "stylesheet",
                    href: "https://rawgit.com/lykmapipo/themify-icons/master/css/themify-icons.css",
                },
            ],
        };
    },
};
</script>
