<template>
    <hooper :settings="settings">
        <slide v-for="(slide, index) in company.banners" :key="index" class="or-slider">
            <template v-if="slide.link">
                <a :href="slide.link" target="_blank">
                    <img :src="getMediaPath(slide.url)" style="width: 100%;height: 100%;" />
                </a>
            </template>

            <template v-else>
                <img :src="getMediaPath(slide.url)" style="width: 100%;height: 100%;" />
            </template>
        </slide>
        <hooper-navigation slot="hooper-addons">
            <div slot="hooper-next" class="dot">
                <svg class="icon icon-arrowRight" viewBox="0 0 24 24" width="30px" height="30px">
                    <title>Arrow Right</title>
                    <path d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z"></path>
                </svg>
            </div>
            <div slot="hooper-prev" class="dot">
                <svg class="icon icon-arrowLeft" viewBox="0 0 24 24" width="30px" height="30px">
                    <title>Arrow Left</title>
                    <path d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M15.41 16.59L10.83 12l4.58-4.59L14 6l-6 6 6 6 1.41-1.41z"></path>
                </svg>
            </div>
        </hooper-navigation>
    </hooper>
</template>

<script>
import { Hooper, Slide, Navigation as HooperNavigation } from "hooper";
import LoadingMixin from '@/plugins/Common/LoadingMixin.js'

import "hooper/dist/hooper.css";
import { mapGetters } from "vuex";
export default {
    name: "SliderArea",
    data() {
        return {
            settings: {
                itemsToShow: 1,
                centerMode: true,
                autoPlay: true,
                playSpeed: 5000,
            },
        };
    },
    computed: {
        ...mapGetters({
            company: "general/allInfo",
        }),
    },
    mixins: [LoadingMixin],
    components: { Hooper, Slide, HooperNavigation },
};
</script>

<style scoped>
.hooper {
    height: 100%;
    width: 100%;
}

.or-slider {
    height: 150px;
    width: 100%;
    background-color: #000;
}

@media (min-width: 300px) {
    .or-slider {
    height: 150px;
    }
}
@media (min-width: 640px) {
    .or-slider {
    height: 300px;
    }
}
@media (min-width: 768px) {
    .or-slider {
    height: 430px;
    }
}
@media (min-width: 1024px) {
    .or-slider {
        height: 430px;
    }
}
</style>
