<template>
    <div>
        <div class="product_wrappers_one">
            <div class="thumb">
                <router-link :to="{ path: '/products/' + product.online_store_slug }" class="image">
                    <img v-if="productToShow" :src="getMediaPath(productToShow.photo)" :alt="productToShow.title"/>
                </router-link>
                <span class="badges">
                    <span class="hot" v-if="product.stock.out_stock && !product.can_variant">Sin Stock</span>
                    <span class="" v-else></span>
                </span>
                <div class="actions">
                    <button
                        id="toggle-btn"
                        @click="toggleModal"
                        class="action quickview"
                        title="Vista rapida"
                    >
                        <i class="fas fa-expand"></i>
                    </button>
                </div>
                <template v-if="inCart">
                    <button
                        class="add-to-cart offcanvas-toggle"
                        title="Ya esta en el Carrito"
                    >
                        Ya esta en el Carrito
                    </button>
                </template>
                <template v-else>
                    <button
                        v-if="!productToShow.stock.out_stock"
                        @click="checkAddToCart(product)"
                        class="add-to-cart offcanvas-toggle"
                        title="Agregar al carrito"
                    >
                        Agregar al carrito
                    </button>
                </template>
            </div>

            <div class="content">
                <h5 class="title text-capitalize">
                    <router-link :to="{ path: '/products/' + product.online_store_slug }">{{product.name}}</router-link>
                </h5>
                <span class="price">
                    <template v-if="productToShow">
                    <span class="new">{{ productToShow.price.value_price | currencyFormat }}</span>
                    <span class="new text-secondary" v-if="productToShow.price.special_price" style="text-decoration: line-through;">{{ productToShow.price.retail_price | currencyFormat }}</span>
                    </template>
                </span>
            </div>

            <!-- Modal Area Start-->
            <div>
                <b-modal
                    ref="product-details-popup"
                    centered
                    hide-footer
                    title="Using Component Methods"
                    class="product_one_modal_top"
                    id="product_slider_one"
                >
                    <template #modal-header="{ close }">
                        <button
                            type="button"
                            class="close close_modal_icon"
                            @click="close()"
                        >
                            <span aria-hidden="true"><i class="fas fa-times"></i></span>
                        </button>
                    </template>

                    <div>
                        <div class="row">
                            <div class="col-lg-5 col-md-6 col-sm-12 col-12">
                                <div class="products_modal_sliders">
                                    <div v-swiper:mySwiper="swiperOption" ref="mySwiper">
                                        <div class="swiper-wrapper">
                                            <div class="swiper-slide" :key="index">
                                                <img
                                                    :src="getMediaPath(productToShow.photo)"
                                                    class="img-fluid bg-img"
                                                    alt="imag.alt"
                                                />
                                                <span class="badges">
                                                    <span class="hot" v-if="productToShow.stock.out_stock && !product.can_variant">Sin Stock</span>
                                                    <span class="" v-else></span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-7 col-md-6 col-sm-12 col-12">
                                <div class="modal_product_content_one">
                                    <h3 class="text-capitalize">{{ product.name }}</h3>
                                    <template v-if="productToShow">
                                    <h4>{{ productToShow.price.value_price | currencyFormat }}</h4>
                                    <h4 v-if="productToShow.price.special_price" style="text-decoration: line-through;" class="text-secondary">
                                        <del>{{ productToShow.price.retail_price | currencyFormat }}</del>
                                    </h4>
                                    </template>


                                    <p style="white-space: break-spaces">{{ productToShow.online_store_description }}</p>

                                    <div v-if="product.can_variant && product.variants.length > 0">
                                        <br>

                                        <select
                                            class="form-control"
                                            v-model="variantSelectedId"
                                            id="variant_product"
                                            @change="onChangeVariantSelectedId"
                                        >
                                            <option v-for="item in product.variants" :value="item.id">
                                                {{item.name}}
                                            </option>
                                        </select>
                                    </div>

                                    <template v-if="inCart">
                                        <a
                                            href="javascript:void(0)"
                                            class="theme-btn-one btn-black-overlay btn_sm"
                                            >Ya esta en el Carrito</a
                                        >
                                        <p></p>
                                    </template>
                                    <template v-else>
                                        <template v-if="product.can_variant && variantSelectedId == null">
                                            Seleecione una variante
                                        </template>
                                        <template v-else>
                                    <form
                                        v-if="!productToShow.stock.out_stock"
                                        id="product_count_form_one"
                                    >
                                        <div class="product_count_one">
                                            <b-form-spinbutton
                                                id="sb-inline"
                                                min="1"
                                                max="100"
                                                v-model="quantity"
                                                inline
                                                class="border-0"
                                                :disabled="status.loading"
                                            ></b-form-spinbutton>
                                            <a
                                                href="javascript:void(0)"
                                                @click="addToCart(product)"
                                                class="theme-btn-one btn-black-overlay btn_sm"
                                            >
                                                Agregar al Carrito
                                            </a>
                                        </div>
                                    </form>
                                        </template>
                                    </template>
                                </div>
                            </div>
                        </div>
                    </div>
                </b-modal>
            </div>
            <!-- Modal Area End-->
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";
import LoadingMixin from '@/plugins/Common/LoadingMixin.js'

export default {
    name: "ProductCard",
    props: ["product", "index"],
    data() {
        return {
            variantSelectedId: null,
            variantSelected: null,
            productToShow: null,
            // Product Quanity Increment/ Decrement Data
            quantity: 1,
            cartProduct: {},
            compareProduct: {},
            dismissSecs: 3,
            dismissCountDown: 0,
            quantity: 1,
            qty: "",
            swiperOption: {
                slidesPerView: 1,
                spaceBetween: 20,
                freeMode: true,
            },
        };
    },
    computed: {
        inCart () {
            let object
            if (this.variantSelectedId) {
                object = this.cart.items.find(item => item.product.id === this.variantSelectedId)
            } else {
                object = this.cart.items.find(item => item.product.id === this.product.id)
            }
            if (object) return true
            return false
        },
        ...mapState({
            cart: state => state.cart.cart,
            productslist: (state) => state.products.productslist,
            cartError: (state) => state.cart.error,
        }),
        swiper() {
            return this.$refs.mySwiper.swiper;
        },
    },
    created() {
        if (this.product) {
            if (this.product.can_variant) {
                this.productToShow = this.product.variants[0]
                this.variantSelectedId = this.productToShow.id
            } else {
                this.productToShow = this.product
            }
        }
    },
    methods: {
        onChangeVariantSelectedId () {
            if (this.variantSelectedId !== null && this.variantSelectedId !== undefined) {
                this.variantSelected = this.product.variants.find(item => item.id === this.variantSelectedId)
                this.productToShow = this.product.variants.find(item => item.id === this.variantSelectedId)
            } else {
                this.variantSelected = null
                this.productToShow = null
            }
        },
        // Product details Popup id Methods
        toggleModal() {
            this.$refs["product-details-popup"].toggle("#toggle-btn");
        },
        // Product Add To Cart realted methods
        checkAddToCart (product) {
            if (this.product.can_variant) {
                this.toggleModal()
            } else {
                this.addToCart(product)
            }
        },
        addToCart (product) { // ya no va este param quantity
            if (this.statusIsLoading()) return

            this.statusLoading()

            let itemToCart = null

            if (this.variantSelectedId !== null && this.variantSelectedId !== undefined) {
                const variant = product.variants.find(item => item.id === this.variantSelectedId)
                if (variant) {
                    itemToCart = variant
                }
            } else {
                itemToCart = product
            }

            this.$Models.Cart.add(itemToCart, this.quantity ? this.quantity : 1)
                .then(res => {
                    this.statusLoadingComplete()
                    if (res.ok) {
                        this.$store.commit('cart/setCart', res.cart)
                        // this.showSuccessMessage(res.message)
                        // this.$refs.dialogAddToCartSuccess.open()

                        this.$swal({
                            title: "Excelente",
                            text: "El producto se ha agregado al carrito",
                            icon: "success",
                            showCancelButton: true,
                            confirmButtonColor: "#EFC437",
                            cancelButtonColor: "#000",
                            cancelButtonText: "Seguir comprando",
                            confirmButtonText: "Ir al carrito",
                        }).then((result) => {
                            if (result.isConfirmed) {
                                this.$router.push("/cart");
                            }
                        });
                    } else {
                        this.$store.commit('cart/setCart', res.cart)
                        this.showError(res.message)
                    }
                })
                .catch((err) => {
                    this.statusLoadingFail()
                    this.showError(err)
                })
        },
        // After Add to cart Alert
        countDownChanged(dismissCountDown) {
            this.dismissCountDown = dismissCountDown;
            this.$emit("alertseconds", this.dismissCountDown);
        },
        slideTo(id) {
            this.swiper.slideTo(id, 1000, false);
        },
    },
    watch: {
        product (nVal, oVal) {
            if (nVal) {
                if (nVal.can_variant) {
                    this.productToShow = nVal.variants[0]
                    this.variantSelectedId = this.productToShow.id
                } else {
                    this.productToShow = nVal
                }
            }
        }
    },
    mixins: [LoadingMixin]
};
</script>
