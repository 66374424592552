function format (value) {
  return Math.round(value)
}

const toFixedNumber = (num, toFixTo = 2, base = 10) => {
  /*
  const pow = Math.pow(base, toFixTo)
  return +(Math.round(num * pow) / pow)
  */

  // ó

  /*
  return parseFloat(num.toFixed(2))
  */

  // ó

  /*
  return Math.round(num * 100) / 100
  */

  // ó

  /* // Mejor opcion hasta el momento
  */
  var pow = Math.pow(base, toFixTo)
  return Math.round(num * pow) / pow
}

function quantityFormat (value) {
  if (value === null || value === undefined) {
    return 0
  }
  if (typeof value !== 'number') {
    // console.error(`quantityFormat error formating value: ${value} type: ${typeof value}`)
    throw new Error('quantityFormat not in number')
  }
  if (value % 1 === 0) {
    return format(value)
  } else {
    // WARN: revisar (compatibilidad en navegadores y redondeo de decimal)
    return toFixedNumber(value)
  }
}

export {
  format,
  quantityFormat
}
