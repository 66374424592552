import Builder from '@/api/ORM/Builder.js'

// import _ from '@/helpers/lodash'
import { RestConnection } from '@/api/ORM/connection'

class Model {
  constructor (attributes = {}) {
    Object.defineProperties(this, {
      original: {
        writable: true
      },
      exists: {
        writable: true
      }
    })

    this.original = attributes

    this.exists = false

    this.fill(attributes)

    this._syncOriginal()
  }

  getConnection () {
    if (!this.connection) {
      this.connection = new RestConnection(this.constructor.endpoint)
    }
    return this.connection
  }

  fill (attributes) {
    for (const key in attributes) {
      this.setAttribute(key, attributes[key])
    }

    return this
  }

  _syncOriginal () {
    this.original = this.getAttributes()
  }

  getAttribute (key) {
    return this[key]
  }

  setAttribute (key, newValue) {
    this[key] = newValue

    return this
  }

  getAttributes () {
    const cloned = Object.assign({}, this)

    return cloned
  }

  getDirty () {
    const attributes = this.getAttributes()

    this.ignoredAttributes.forEach(attr => {
      delete attributes[attr]
    })

    for (const prop in attributes) {
      if (
        typeof this.original[prop] !== 'undefined' && (
          (this.original[prop] === null && attributes[prop] === null) || (
            this.original[prop] !== null &&
            attributes[prop] !== null
            // this.original[prop].valueOf() === attributes[prop].valueOf()
            // _.isEqual(this.original[prop], attributes[prop])
          )
        )
      ) {
        delete attributes[prop]
      }
    }

    return attributes
  }

  getKey () {
    return this[this.getKeyName()]
  }

  getKeyName () {
    return this.constructor.primaryKey || 'id'
  }

  static query () {
    return (new this()).newQuery()
  }

  newQuery () {
    return new Builder(this.getConnection(), this)
  }

  newInstance (attributes = {}, exists = false) {
    const instance = new this.constructor(attributes)
    instance.exists = exists
    return instance
  }

  hydrate (items) {
    return items.map(attributes => this.newInstance(attributes, true))
  }

  static hydrate (items) {
    return items.map(attributes => new this().newInstance(attributes, true))
  }

  static all (columns) {
    return (new this()).newQuery().get(columns)
  }

  refreshAttribute (key) {
    this[key] = this.original[key]
    return this
  }

  refresh () {
    return this
      .newQuery()
      .where(this.getKeyName(), this.getKey())
      .first()
      .then(item => this.fill(item.getAttributes()))
  }
}

export default Model
