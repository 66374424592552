import Vue from "vue";

import VueAwesomeSwiper from "vue-awesome-swiper";
import "swiper/css/swiper.css";

import InnerImageZoom from "vue-inner-image-zoom";
import "vue-inner-image-zoom/lib/vue-inner-image-zoom.css";

import VueSidebarMenu from "vue-sidebar-menu";
import "vue-sidebar-menu/dist/vue-sidebar-menu.css";

import Vuelidate from "vuelidate";
import VueApexCharts from "vue-apexcharts";

require("vue2-animate/dist/vue2-animate.min.css");

import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/default.css";

import VueFormWizard from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";

import VueI18n from "vue-i18n";

import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import Meta from "vue-meta";

import axios from 'axios';
import VueAxios from 'vue-axios';

import VueSweetalert2 from 'vue-sweetalert2';

import 'sweetalert2/dist/sweetalert2.min.css';

Vue.use(VueSweetalert2);

Vue.use(VueAxios, axios)

Vue.use(Meta);
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(VueI18n);
Vue.use(VueAwesomeSwiper);
Vue.component("inner-image-zoom", InnerImageZoom);
Vue.use(VueSidebarMenu);
Vue.use(Vuelidate);
Vue.use(VueApexCharts);
Vue.component("apexchart", VueApexCharts);
Vue.component("VueSlider", VueSlider);
Vue.use(VueFormWizard);
