<template>
    <Layout>
        <div>
            <!-- Banner Area -->
            <section v-if="product" id="common_banner_one">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="common_banner_text">
                                <h2>{{ product.name }}</h2>
                                <b-breadcrumb :items="breadcrumbItems" class="bg-transparent"></b-breadcrumb>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <!-- Product Single Area -->
            <section v-if="product" id="product_single_one" class="ptb-100">
                <div class="container">
                    <div class="row area_boxed">
                        <div class="col-lg-6">
                            <div class="product_single_one_img">
                                <inner-image-zoom
                                    :src="getMediaPath(productToShow.photo)"
                                    :zoomSrc="getMediaPath(productToShow.photo)"
                                    moveType="drag"
                                    className="product-image-zoom"
                                />
                                <span class="badges">
                                    <span class="hot" v-if="productToShow.stock.out_stock && !productToShow.can_variant">Sin Stock</span>
                                    <span class="" v-else></span>
                                </span>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="product_details_right_one">
                                <div class="modal_product_content_one">
                                    <h3 class="text-capitalize">{{ product.name }}</h3>
                                    <h5 v-if="productToShow">Ref: {{ productToShow.sku }}</h5>

                                    <template v-if="productToShow">
                                    <h4>
                                        {{ productToShow.price.value_price | currencyFormat }}
                                        <span v-if="productToShow.price.special_price" style="text-decoration: line-through;" class="text-secondary">{{productToShow.price.retail_price | currencyFormat}}</span>
                                    </h4>
                                    </template>
                                    <!--p style="white-space: break-spaces">
                                        {{ productToShow.online_store_description }}
                                    </p-->

                                    <div v-if="product.can_variant && product.variants.length > 0">
                                        <br>

                                        <select
                                            class="form-control"
                                            v-model="variantSelectedId"
                                            id="variant_product"
                                            @change="onChangeVariantSelectedId"
                                        >
                                            <option v-for="item in product.variants" :value="item.id">
                                                {{item.name}}
                                            </option>
                                        </select>
                                    </div>

                                    <template v-if="inCart">
                                        <div class="links_Product_areas">
                                            <button class="theme-btn-one btn-black-overlay btn_sm">
                                                Ya esta en el Carrito
                                            </button>
                                        </div>
                                    </template>
                                    <template v-else>
                                        <template v-if="product.can_variant && variantSelectedId == null">Seleecione una variante</template>
                                        <template v-else>
                                        <form v-if="!productToShow.stock.out_stock" id="product_count_form_two">
                                            <div class="product_count_one">
                                                <b-form-spinbutton
                                                    id="sb-inline"
                                                    min="1"
                                                    max="100"
                                                    v-model="quantity"
                                                    :disabled="status.loading"
                                                    inline
                                                    class="border-0"
                                                ></b-form-spinbutton>
                                            </div>
                                        </form>
                                        <div v-if="!productToShow.stock.out_stock" class="links_Product_areas">
                                            <button @click="addToCart(product)" class="theme-btn-one btn-black-overlay btn_sm">Agregar al carrito</button>
                                        </div>
                                        </template>
                                    </template>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="product_details_tabs">
                                <b-tabs>
                                    <b-tab
                                        title="Descripción"
                                        active
                                        id="description"
                                    >
                                        <div class="product_description">
                                            <p style="white-space: break-spaces;">{{productToShow.online_store_description}}</p>
                                        </div>
                                    </b-tab>

                                    <!--b-tab
                                        title="Información adicional"
                                        id="additional"
                                    >
                                        <div class="product_additional"></div>
                                    </b-tab-->
                                </b-tabs>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <!-- Error-Area -->
            <section v-else id="error_area" class="ptb-100">
                <div class="container" v-if="status.loading">
                    <div class="row">
                        <div class="col-lg-6 offset-lg-3">
                            <div class="erorr_wrapper">
                                <!--h1>Cargando</h1-->
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container" v-else>
                    <div class="row">
                        <div class="col-lg-6 offset-lg-3">
                            <div class="erorr_wrapper">
                                <h1>404</h1>
                                <h3>Lo sentimos, la página solicitada no está disponible.!</h3>
                                <router-link to="/" class="theme-btn-one btn-black-overlay btn_md">Volver al inicio</router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </Layout>
</template>

<script>
import ProductCard from "@/components/molecules/product.card.vue";
import RelatedProducts from "@/components/organism/product.related.vue";
import Layout from "@/components/layouts/main.vue";
import { mapGetters } from "vuex";
import { mapState } from "vuex";

import LoadingMixin from '@/plugins/Common/LoadingMixin.js'

export default {
    name: "product-single",
    components: {
        ProductCard,
        RelatedProducts,
        Layout,
    },
    data() {
        return {
            product: null,
            variantSelectedId: null,
            variantSelected: null,
            productToShow: null,
            ref: this.$route.params.id,
            qty: "",
            // Breadcrumb Items Data
            breadcrumbItems: [
                {
                    text: "Inicio",
                    to: "/",
                },
                {
                    text: "Producto",
                    to: "/product",
                },
            ],
            // Product Quanity Increment/ Decrement Data
            quantity: 1,
            cartProduct: {},
            swiperOption: {
                slidesPerView: 1,
                spaceBetween: 20,
                freeMode: true,
            },
        };
    },
    created() {
        this.load()
    },
    beforeRouteEnter (to, from, next) {
        next(vm => {
            // check later revisar esto
            vm.load(to.params.productStoreSlug)
        })
    },
    computed: {
        inCart () {
            let object
            if (this.variantSelectedId) {
                object = this.cart.items.find(item => item.product.id === this.variantSelectedId)
            } else {
                object = this.cart.items.find(item => item.product.id === this.product.id)
            }
            if (object) return true
            return false
        },
        swiper() {
            return this.$refs.mySwiper.swiper;
        },
        ...mapState({
            cart: state => state.cart.cart,
        }),
    },
    mounted() {
        // For displaying default color and size on pageload
        // For scroll page top for every Route
        window.scrollTo(0, 0);
    },
    methods: {
        onChangeVariantSelectedId () {
            if (this.variantSelectedId !== null && this.variantSelectedId !== undefined) {
                this.variantSelected = this.product.variants.find(item => item.id === this.variantSelectedId)
                this.productToShow = this.product.variants.find(item => item.id === this.variantSelectedId)
            } else {
                this.variantSelected = null
                this.productToShow = null
            }
        },
        addToCart (product) { // ya no recive este param quantity
            if (this.statusIsLoading()) return

            this.statusLoading()

            let itemToCart = null

            if (this.variantSelectedId !== null && this.variantSelectedId !== undefined) {
                const variant = product.variants.find(item => item.id === this.variantSelectedId)
                if (variant) {
                    itemToCart = variant
                }
            } else {
                itemToCart = product
            }

            this.$Models.Cart.add(itemToCart, this.quantity ? this.quantity : 1)
                .then(res => {
                    this.statusLoadingComplete()
                    if (res.ok) {
                        this.$store.commit('cart/setCart', res.cart)
                        // this.showSuccessMessage(res.message)
                        // this.$refs.dialogAddToCartSuccess.open()
                        this.$swal({
                            title: "Excelente",
                            text: "El producto se ha agregado al carrito",
                            icon: "success",
                            showCancelButton: true,
                            confirmButtonColor: "#EFC437",
                            cancelButtonColor: "#000",
                            cancelButtonText: "Seguir comprando",
                            confirmButtonText: "Ir al carrito",
                        }).then((result) => {
                            if (result.isConfirmed) {
                                this.$router.push("/cart");
                            }
                        });
                    } else {
                        this.$store.commit('cart/setCart', res.cart)
                        this.showError(res.message)
                    }

                })
                .catch((err) => {
                    this.statusLoadingFail()
                    this.showError(err)
                })
        },
        // Related Product Display
        relatedProducts() {},

        slideTo(id) {
            this.swiper.slideTo(id, 1000, false);
        },
        load (productStoreSlug) {
            if (this.statusIsLoading()) return
            this.statusLoading()

            this.$Models.Product
                .query()
                .where('online_store_slug', productStoreSlug ? productStoreSlug : this.$route.params.productStoreSlug)
                .first()
                .then(product => {
                    this.statusLoadingComplete()
                    this.product = product
                    if (this.product.can_variant) {
                        this.productToShow = this.product.variants[0]
                        this.variantSelectedId = this.productToShow.id
                    } else {
                        this.productToShow = product
                    }
                })
                .catch(err => {
                    this.statusLoadingFail()
                    this.showError(err)
                })
        }
    },
    mixins: [LoadingMixin],
    // Page head() Title, description for SEO
    head() {
        return {
            title: this.title,
            meta: [
                {
                    hid: "description",
                    name: "description",
                    content: "Producto - Organic Nails Tienda oficial",
                },
            ],
        };
    },
};
</script>
