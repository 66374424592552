const Mixin = {
  data () {
    return {
      scriptAdded: false,
      siteKey: null
    }
  },
  methods: {
    wathInfoSiteKey (nVal, oVal) {
      if (nVal.site_key) {
        this.siteKey = nVal.site_key
        this.appendScriptRecaptcha()
      }
    },
    appendScriptRecaptcha () {
      if (this.scriptAdded) { return }

      this.scriptAdded = true

      const recaptchaScript = document.createElement('script')
      recaptchaScript.setAttribute('src', `https://www.google.com/recaptcha/api.js?render=${this.siteKey}`)
      document.head.appendChild(recaptchaScript)

      // recaptchaScript.onload = () => { this.initRecaptcha() }
    },
    onGetRecaptchaToken (token) {
    },
    getRecaptchaToken (action) {
      // eslint-disable-next-line no-undef
      const execution = grecaptcha.execute(this.company.site_key, { action })

      return execution
        .then((token) => {
          this.onGetRecaptchaToken(token)
          return token
        })
    },
    initRecaptcha () {
      // eslint-disable-next-line no-undef
      grecaptcha.ready(() => {
        this.getRecaptchaToken()
      })
    }
  }
}

export default Mixin
