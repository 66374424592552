import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/home.view.vue";
import Products from "../views/products.view.vue";
import Product from "../views/product.view.vue";
import Checkout from "../views/checkout.view.vue";
import OrderComplete from "../views/order-comple.view.vue";
import LegalPage from "../views/legal-page.view.vue";
import Cart from "../views/cart.view.vue";
import Error404 from "../views/404.view.vue";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        component: Home,
        name: 'routes.name.home',
    },
    {
        path: "/products",
        component: Products,
        name: 'routes.name.products',
    },
    {
        path: "/products/:productStoreSlug",
        component: Product,
        name: 'routes.name.productDetail',
    },
    {
        path: "/cart",
        component: Cart,
        name: 'routes.name.cart',
    },
    {
        path: "/cart/summary",
        component: Checkout,
        name: 'routes.name.cartSummary',
    },
    {
        path: '/orders/complete',
        component: OrderComplete,
        name: 'portal.orders.complete',
    },
    {
        path: "/contact",
        component: Home,
        name: 'routes.name.contact',
    },
    {
        path: "/sobre-nosotros",
        component: Home,
        name: 'routes.name.aboutus',
    },
    {
        path: "/categories/:categoryStoreSlug",
        component: Products,
        name: 'routes.name.categodyDetail',
    },
    {
        path: "/legal/:legalName",
        component: LegalPage,
        name: 'routes.name.legalPage',
    },
    { path: "*", component: Error404 }
];

const router = new VueRouter({
    mode: 'history',
    routes,
});

export default router;
