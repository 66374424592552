<template>
    <Layout>
        <div>
            <!-- Banner Area -->
            <section id="common_banner_one">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="common_banner_text">
                                <h2>{{ this.title }}</h2>
                                <b-breadcrumb :items="breadcrumbItems" class="bg-transparent"></b-breadcrumb>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <!-- Checkout-Area -->
            <section id="checkout_one" class="ptb-100">
                <div class="container">
                    <form-wizard step-size="sm" title="" back-button-text="Anterior" finish-button-text="Finalizar compra"
                        next-button-text="Siguiente" subtitle="" @on-change="handleTabChange" @on-complete="preparePay" >
                        <tab-content title="Información" color="#000" icon="ti-user" :before-change="validateFirstTab">
                            <div class="container">
                                <div class="row">
                                    <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                                        <div class="bg-white">
                                            <div class="check-heading">
                                                <h3>Información de facturación</h3>
                                            </div>
                                            <div class="row">
                                                <div class="col-lg-6 col-md-12 col-sm-12 col-12">
                                                    <div class="check-out-form">
                                                        <div class="row">
                                                            <div class="col-lg-4 col-md-12 col-sm-12 col-12">
                                                                <div class="form-group">
                                                                    <label for="fname">Nombres</label>
                                                                    <input type="text" v-model="user.fname" id="fname" name="fname" class="form-control" placeholder="Nombres *" :class="{'is-invalid':$v.user.fname.$error,}" />
                                                                    <div v-if="!$v.user.fname.required" class="invalid-feedback">Este campo es requerio</div>
                                                                </div>
                                                            </div>

                                                            <div class="col-lg-4 col-md-12 col-sm-12 col-12">
                                                                <div class="form-group">
                                                                    <label for="lname">Apellidos</label>
                                                                    <input type="text" v-model="user.lname" id="lname" name="lname" class="form-control" placeholder="Apellidos *" :class="{'is-invalid':$v.user.lname.$error,}" />
                                                                    <div v-if="!$v.user.lname.required" class="invalid-feedback">Este campo es requerido</div>
                                                                </div>
                                                            </div>

                                                            <div class="col-lg-4 col-md-12 col-sm-12 col-12">
                                                                <div class="form-group">
                                                                    <label for="document">Identificación</label>
                                                                    <input type="text" v-model="user.document" id="document" name="document" class="form-control" placeholder="Número de identificación *" :class="{'is-invalid':$v.user.document.$error,}" />
                                                                    <div v-if="!$v.user.document.required" class="invalid-feedback">Este campo es requerido</div>
                                                                </div>
                                                            </div>

                                                            <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                                                                <div class="form-group">
                                                                    <label for="email">Correo electronico<span></span></label>
                                                                    <input type="email" v-model="user.email" id="email" name="email" class="form-control" placeholder="ejemplo@ejemplo.com" :class="{'is-invalid':$v.user.email.$error,}" />
                                                                    <div v-if="$v.user.email.$error" class="invalid-feedback">
                                                                        <span v-if="!$v.user.email.required">Este campo es requerido</span>
                                                                        <span v-if="!$v.user.email.email">Formato no válido</span>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div class="col-lg-6 col-md-12 col-sm-12 col-12">
                                                                <div class="form-group">
                                                                    <label for="dept">Departamento</label>
                                                                    <select class="form-control first_null" @change="onChangeShippingDept" v-model="user.dept" id="dept" :class="{'is-invalid':$v.user.dept.$error,}">
                                                                        <option v-for="item in depts" :value="item.code">{{item.name}}</option>
                                                                    </select>
                                                                    <div v-if="!$v.user.dept.required" class="invalid-feedback"> Este campo es requerido</div>
                                                                </div>
                                                            </div>

                                                            <div class="col-lg-6 col-md-12 col-sm-12 col-12">
                                                                <div class="form-group">
                                                                    <label for="city">Ciudad</label>
                                                                    <select class="form-control first_null" v-model="user.city" @change="onChaShippingingMunp" id="city" :class="{'is-invalid':$v.user.city.$error,}">
                                                                        <option v-for="item in munpShippingToShow" :value="item.code">{{item.name}}</option>
                                                                    </select>
                                                                    <div v-if="!$v.user.city.required" class="invalid-feedback">Este campo es requerido</div>
                                                                </div>
                                                            </div>

                                                            <div v-if="submunpShippingToShow.length > 0" class="col-lg-6 col-md-12 col-sm-12 col-12">
                                                                <div class="form-group">
                                                                    <label for="subcity">Distrito</label>
                                                                    <select class="form-control first_null" v-model="user.subcity" @change="onChaShippingingSubmunp" id="subcity" :class="{'is-invalid':$v.user.subcity.$error,}">
                                                                        <option v-for="item in submunpShippingToShow" :value="item.code">{{item.name}}</option>
                                                                    </select>
                                                                    <div v-if="!$v.user.subcity.required" class="invalid-feedback">Este campo es requerido</div>
                                                                </div>
                                                            </div>

                                                            <div class="col-lg-6 col-md-12 col-sm-12 col-12">
                                                                <div class="form-group">
                                                                    <label for="address">Dirección</label>
                                                                    <input type="text" class="form-control" v-model=" user.address" @change="onChangeShippingAddress" id="address" placeholder="Ingrese aquí la dirección .." :class="{'is-invalid':$v.user.address.$error,}" />
                                                                    <div v-if="!$v.user.address.required" class="invalid-feedback">Este campo es requerido</div>
                                                                </div>
                                                            </div>

                                                            <div class="col-lg-6 col-md-12 col-sm-12 col-12">
                                                                <div class="form-group">
                                                                    <label for="phone">Celular</label>
                                                                    <input type="text" class="form-control" v-model="user.phone" id="phone" placeholder="Ingrese aquí la dirección .." :class="{'is-invalid':$v.user.phone.$error,}" />
                                                                    <div v-if="!$v.user.phone.required" class="invalid-feedback">Este campo es requerido </div>
                                                                </div>
                                                            </div>

                                                            <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                                                                <div class="form-group">
                                                                    <b-form-checkbox id="billing" v-model="user.billing" name="billing" @change="onChangeUseSameAddress">La dirección de envío es igual a la dirección de facturación</b-form-checkbox>
                                                                </div>
                                                            </div>


                                                            <template v-if="!user.billing">
                                                                <div class="col-lg-6 col-md-12 col-sm-12 col-12">
                                                                    <div class="form-group">
                                                                        <label for="dept">Departamento</label>
                                                                        <select class="form-control first_null" @change="onChangeBillingDept" v-model="user.billing_dept" id="dept" :class="{'is-invalid':$v.user.billing_dept.$error,}">
                                                                            <option v-for="item in depts" :value="item.code">{{item.name}}</option>
                                                                        </select>
                                                                        <div v-if="!$v.user.billing_dept.required" class="invalid-feedback"> Este campo es requerido</div>
                                                                    </div>
                                                                </div>

                                                                <div class="col-lg-6 col-md-12 col-sm-12 col-12">
                                                                    <div class="form-group">
                                                                        <label for="billing_city">Ciudad</label>
                                                                        <select class="form-control first_null" v-model="user.billing_city" @change="onChaBillingMunp" id="billing_city" :class="{'is-invalid':$v.user.billing_city.$error,}">
                                                                            <option v-for="item in munpBillingToShow" :value="item.code">{{item.name}}</option>
                                                                        </select>
                                                                        <div v-if="!$v.user.billing_city.required" class="invalid-feedback">Este campo es requerido</div>
                                                                    </div>
                                                                </div>

                                                                <div v-if="submunpBillingToShow.length > 0" class="col-lg-6 col-md-12 col-sm-12 col-12">
                                                                    <div class="form-group">
                                                                        <label for="billing_subcity">Distrito</label>
                                                                        <select class="form-control first_null" v-model="user.billing_subcity" id="billing_subcity" :class="{'is-invalid':$v.user.billing_subcity.$error,}">
                                                                            <option v-for="item in submunpBillingToShow" :value="item.code">{{item.name}}</option>
                                                                        </select>
                                                                        <div v-if="!$v.user.billing_subcity.required" class="invalid-feedback">Este campo es requerido</div>
                                                                    </div>
                                                                </div>

                                                                <div class="col-lg-6 col-md-12 col-sm-12 col-12">
                                                                    <div class="form-group">
                                                                        <label for="address">Dirección</label>
                                                                        <input type="text" class="form-control" v-model=" user.billing_address" id="address" placeholder="Ingrese aquí la dirección .." :class="{'is-invalid':$v.user.billing_address.$error,}" />
                                                                        <div v-if="!$v.user.address.required" class="invalid-feedback">Este campo es requerido</div>
                                                                    </div>
                                                                </div>
                                                            </template>

                                                            <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                                                                <div class="form-group">
                                                                    <label for="messages">Notas adicionales</label>
                                                                    <textarea rows="5" class="form-control" v-model="user.messages" id="messages" placeholder="Notas de la compra"></textarea>
                                                                </div>
                                                            </div>

                                                            <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                                                                <div class="form-group">
                                                                    <b-form-checkbox id="terms" v-model="user.terms" name="terms" @change="$v.user.terms.$touch()">
                                                                        Acepto terminos y condiciones
                                                                    </b-form-checkbox>
                                                                    <div v-if="!$v.user.terms.checked" class="invalid-feedback" style="display: inline;">Debe aceptar los términos y condiciones para continuar</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-6 col-md-12 col-sm-12 col-12">
                                                    <div class="coupon_code right" data-aos="fade-up" data-aos-delay="400"
                                                        style="margin-top: 30px">
                                                        <h3 style="color: white !important;">Resumen de compra</h3>
                                                        <div class="coupon_inner">
                                                            <div class="cart_subtotal">
                                                                <p>Subtotal</p>
                                                                <p class="cart_amount">{{cart.totals.total - cart.totals.shipping + cart.totals.discount | currencyFormat}}</p>
                                                            </div>
                                                            <div v-if="company.shipping_method === 'VARIABLE_SHIPPING'">
                                                            </div>
                                                            <div v-else class="cart_subtotal">
                                                                <p>Envío</p>
                                                                <p class="cart_amount">{{cart.totals.shipping | currencyFormat}}</p>
                                                            </div>
                                                            <div v-if="cart.totals.discount > 0" class="cart_subtotal">
                                                                <p>Descuento</p>
                                                                <p class="cart_amount">{{cart.totals.discount | currencyFormat}}</p>
                                                            </div>
                                                            <div class="cart_subtotal">
                                                                <p>Total</p>
                                                                <p class="cart_amount">{{cart.totals.total | currencyFormat}}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </tab-content>
                        <tab-content title="Envío" icon="ti-truck" :before-change="validateSecondTab">
                            <div v-if="company.shipping_method === 'VARIABLE_SHIPPING'">
                                <h5 class="my-5">El precio de envío dependera de su ubicación</h5>
                            </div>
                            <div v-else class="payment_method">
                                <div class="payment_option">
                                    <div class="custome-radio">
                                        <b-form-radio v-model="selected_delivery" name="cost_delivery" value="true">
                                            <span style="font-weight: bold;">Precio: {{cart.totals.shipping | currencyFormat}}</span>
                                        </b-form-radio>
                                        <p data-method="option5" class="payment-text">Precio de envio de su compra</p>
                                    </div>
                                </div>
                            </div>
                        </tab-content>
                        <tab-content title="Pago" icon="ti-money" :before-change="validateThirdTab">
                            <div class="payment_method">
                                <div class="payment_option">
                                    <div class="custome-radio">
                                        <b-form-radio v-model="payment_option" name="payment_option" value="PAYMENT_GATEWAY">
                                            <span style="font-weight: bold;">Mercado Pago</span>
                                        </b-form-radio>

                                        <p style="white-space: pre-line;">{{company.option_payment_online_text}}</p>
                                        <br>
                                    </div>
                                    <div class="custome-radio">
                                        <b-form-radio v-model="payment_option" name="payment_option" value="EXTERNAL_TRANSFER">
                                            <span style="font-weight: bold;">Transferencia bancaria</span>
                                        </b-form-radio>

                                        <p style="white-space: pre-line;">{{company.option_payment_transfer_text}}</p>
                                        <br>
                                    </div>
                                </div>
                            </div>
                        </tab-content>
                        <tab-content title="Completo" icon="ti-check">
                            <div class="container">
                                <div class="row">
                                    <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                                        <div class="bg-white">
                                            <div class="check-heading">
                                                <h3>Resumen de compra</h3>
                                            </div>
                                            <div class="row">
                                                <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                                                    <div class="check-out-form">
                                                        <b-card
                                                            bg-variant="light"
                                                            header="Dirección de entrega"
                                                        >
                                                            <b-card-text>{{user.address}}</b-card-text>
                                                            <b-card-text>{{getDeptName(user.dept)}}</b-card-text>
                                                            <b-card-text>{{getMunpName(user.city)}}</b-card-text>
                                                            <b-card-text>{{getSubmunpName(user.city, user.subcity)}}</b-card-text>
                                                            <b-card-text>Contacto: {{user.phone}}</b-card-text>
                                                            <b-card-text>Nota de pedido: {{user.messages}}</b-card-text>
                                                        </b-card>
                                                    </div>
                                                </div>
                                                <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                                                    <div class="check-out-form">
                                                        <b-card
                                                            bg-variant="light"
                                                            header="Dirección de facturación"
                                                        >
                                                            <b-card-text>{{user.billing_address}}</b-card-text>
                                                            <b-card-text>{{getDeptName(user.billing_dept)}}</b-card-text>
                                                            <b-card-text>{{getMunpName(user.billing_city)}}</b-card-text>
                                                            <b-card-text>{{getSubmunpName(user.billing_city, user.billing_subcity)}}</b-card-text>
                                                            <!--b-card-text>Contacto: {{user.phone}}</b-card-text-->
                                                        </b-card>
                                                    </div>
                                                </div>
                                                <div class="col-lg-8 col-md-8 col-sm-12 col-12">
                                                    <div class="check-out-form">
                                                        <b-card header="Productos del carrito">
                                                            <div v-if="cart.items.length">
                                                                <ul class="offcanvas-cart">
                                                                    <li
                                                                        class="offcanvas-cart-item-single"
                                                                        v-for="(item, index) in cart.items"
                                                                        :key="index"
                                                                    >
                                                                        <div class="offcanvas-cart-item-block">
                                                                            <router-link
                                                                                :to="{path:'/products/' + item.product.online_store_slug}"
                                                                                class="offcanvas-cart-item-image-link"
                                                                            >
                                                                                <img
                                                                                    :src="getMediaPath(item.product.photo)"
                                                                                    alt="img"
                                                                                    class="offcanvas-cart-image"
                                                                                />
                                                                            </router-link>
                                                                            <div class="offcanvas-cart-item-content">
                                                                                <router-link
                                                                                    :to="{path:'/products/' +item.product.online_store_slug}"
                                                                                    class="offcanvas-cart-item-link">{{item.product.name}}</router-link
                                                                                >
                                                                                <div class="offcanvas-cart-item-details">
                                                                                    <span class="offcanvas-cart-item-details-price">{{item.price | currencyFormat}}</span>
                                                                                    <br />
                                                                                    <b-form-spinbutton
                                                                                        id="sb-inline"
                                                                                        min="1"
                                                                                        max="100"
                                                                                        v-model="item.quantity"
                                                                                        :disabled="status.loading"
                                                                                        inline
                                                                                        class="border-1"
                                                                                        @change="updateItemCart(item)"
                                                                                    ></b-form-spinbutton>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="offcanvas-cart-item-delete text-right">
                                                                            <button
                                                                                @click="removeCartItem(item)"
                                                                                class="offcanvas-cart-item-delete bg-transparent remove-btn"
                                                                            >
                                                                                <i class="far fa-trash-alt"></i>
                                                                            </button>
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </b-card>
                                                    </div>
                                                </div>
                                                <div class="col-lg-8 col-md-8 col-sm-12 col-12">
                                                    <div class="check-out-form">
                                                        <b-card bg-variant="light" header="Resumen de pedido">
                                                            <b-card-text>Cantidad de items: {{cart.items.length}}</b-card-text>
                                                            <b-card-text>Subtotal: {{cart.totals.total - cart.totals.shipping + cart.totals.discount | currencyFormat}}</b-card-text>
                                                            <b-card-text>Gastos de envió: {{cart.totals.shipping | currencyFormat}}</b-card-text>

                                                            <br>

                                                           <div v-if="cart.coupon">
                                                                <b-card-text>
                                                                    <span>Cupón {{cart.coupon.code}}:</span>
                                                                    {{cart.totals.discount | currencyFormat}}

                                                                    <button
                                                                        @click="removeCoupon(cart.coupon)"
                                                                        class="offcanvas-cart-item-delete bg-transparent remove-btn"
                                                                    >
                                                                        <i class="far fa-trash-alt"></i>
                                                                    </button>
                                                                    </b-card-text>
                                                            </div>
                                                            <div v-else>
                                                                <b-form-input
                                                                    id="input-live"
                                                                    v-model="couponCode"
                                                                    aria-describedby="input-live-help input-live-feedback"
                                                                    placeholder="Ingresa el codigo de cupon"
                                                                    trim
                                                                ></b-form-input>
                                                                <br />
                                                                <b-button
                                                                    block
                                                                    variant="outline-dark"
                                                                    @click="addCoupon"
                                                                >
                                                                    Aplicar cupón
                                                                </b-button>
                                                            </div>

                                                            <br>

                                                            <b-card-text>Total a Pagar: {{cart.totals.total | currencyFormat}}</b-card-text>
                                                            <hr />
                                                        </b-card>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </tab-content>
                    </form-wizard>
                </div>
            </section>
        </div>
    </Layout>
</template>

<script>
import { required, email } from "vuelidate/lib/validators";
import { FormWizard, TabContent } from "vue-form-wizard";
import Layout from "@/components/layouts/main.vue";
import { mapGetters } from "vuex";
import { mapState } from "vuex";

import MixinPay from '@/mixins/pay'
import LoadingMixin from '@/plugins/Common/LoadingMixin.js'
import RecaptchaMixin from '@/plugins/Common/RecaptchaMixin.js'

export default {
    name: "Checkout",
    data() {
        return {
            recaptchaToken: null,
            checkout: null,
            order: null,

            depts: [],
            munp : [],
            munpShippingToShow : [],
            munpBillingToShow : [],
            submunpShippingToShow : [],
            submunpBillingToShow : [],
            title: "Orden de compra",
            // Breadcrumb Items Data
            breadcrumbItems: [
                {
                    text: "Inicio",
                    to: "/",
                },
                {
                    text: "Orden de Compra",
                    to: "/checkout",
                },
            ],
            // Form Validation
            user: {
                fname: "",
                lname: "",
                email: "",
                document: "",
                dept: "",
                city: "",
                subcity: "",
                address: "",
                phone: "",
                messages: "",
                terms: false,
                billing: true,

                billing_dept: "",
                billing_city: "",
                billing_subcity: "",
                billing_address: ""
            },
            couponCode: '',
            selected_delivery: "true",
            payment_option: null
        };
    },
    validations () {
        const params = {
            user: {
                fname: { required },
                lname: { required },
                email: { required, email },
                document: { required },
                dept: { required },
                city: { required },
                address: { required },

                billing_dept: { required },
                billing_city: { required },
                billing_address: { required },

                phone: { required },
                terms: {
                    required,
                    checked: (val) => {
                        return val;
                    },
                },
            }
        }

        if (this.submunpBillingToShow.length > 0) {
            params.user.billing_subcity = { required }
        }

        if (this.submunpShippingToShow.length > 0) {
            params.user.subcity = { required }
        }

        return params
    },
    components: {
        FormWizard,
        TabContent,
        Layout,
    },
    mounted() {
        // For scroll page top for every Route
        window.scrollTo(0, 0);
        this.load()
    },
    methods: {
        onChangeUseSameAddress () {
            if (this.user.billing) {
                this.user.billing_dept = this.user.dept
                this.user.billing_city = this.user.city
                this.user.billing_subcity = this.user.subcity
                this.user.billing_address = this.user.address
            } else {
                this.user.billing_dept = null
                this.user.billing_city = null
                this.user.billing_subcity = null
                this.user.billing_address = null
            }
        },
        onChangeShippingDept () {
            this.user.city = null
            this.user.subcity = null

            this.submunpShippingToShow = []

            if (this.user.billing) {
                this.user.billing_dept = this.user.dept
                this.user.billing_city = null
                this.user.billing_subcity = null

                this.submunpBillingToShow = []
            }
            if (this.user.dept !== null && this.user.dept !== undefined) {
                const dept = this.depts.find(dept => dept.code == this.user.dept)
                if (dept) {
                    this.munpShippingToShow = this.munp.filter(munp => munp.departament_code === dept.code)
                    if (this.user.billing) {
                        this.munpShippingToShow = this.munp.filter(munp => munp.departament_code === dept.code)
                    }
                }
            }
        },
        onChaShippingingSubmunp () {
            if (this.user.billing) {
                this.user.billing_subcity = this.user.subcity
            }
        },
        onChaShippingingMunp () {
            if (this.user.billing) {
                this.user.billing_city = this.user.city
                const object = this.munpShippingToShow.find(item => item.code === this.user.city)
                if (object) {
                    this.submunpShippingToShow = object.submunicipalities
                    this.submunpBillingToShow = object.submunicipalities
                } else {
                    this.submunpShippingToShow = []
                    this.submunpBillingToShow = []
                }
            } else {
                const object = this.munpShippingToShow.find(item => item.code === this.user.city)
                if (object) {
                    this.submunpShippingToShow = object.submunicipalities
                } else {
                    this.submunpShippingToShow = []
                }
            }
        },
        onChaBillingMunp () {
            if (this.user.billing) {
                // this.user.billing_city = this.user.city
                // const object = this.munpShippingToShow.find(item => item.code === this.user.city)
                // if (object) {
                //     this.submunpBillingToShow = object.submunicipalities
                // } else {
                //     this.submunpBillingToShow = []
                // }
            } else {
                const object = this.munpBillingToShow.find(item => item.code === this.user.billing_city)
                if (object) {
                    this.submunpBillingToShow = object.submunicipalities
                } else {
                    this.submunpBillingToShow = []
                }
            }
        },
        onChangeShippingAddress () {
            if (this.user.billing) {
                this.user.billing_address = this.user.address
            }
        },
        onChangeBillingDept () {
            this.submunpBillingToShow = []
            this.user.billing_city = null
            this.user.billing_subcity = null
            if (this.user.billing_dept !== null && this.user.billing_dept !== undefined) {
                const dept = this.depts.find(dept => dept.code == this.user.billing_dept)
                if (dept) {
                    this.munpBillingToShow = this.munp.filter(munp => munp.departament_code === dept.code)
                }
            }
        },

        onGetRecaptchaToken (token) {
            this.recaptchaToken = token
        },
        load () {
            this.$store.dispatch('general/loadDepartaments')
                .then(departaments => {
                this.depts = departaments

                // this.setItems(this.source)
                })
                // .catch(error => {})

            this.$store.dispatch('general/loadMunicipalities')
                .then(departaments => {
                this.munp = departaments
                this.munpShippingToShow = []
                this.munpBillingToShow = []
                this.submunpShippingToShow = []
                this.submunpBillingToShow = []

                // this.setItems(this.source)
                })
                // .catch(error => {})
        },
        validateSecondTab() {
            if (this.company.shipping_method === 'VARIABLE_SHIPPING') {
                this.selected_delivery = 'cost_delivery'
            }
            if (this.selected_delivery) {
                return true
            }

            this.showError('Verifique la forma de envio')

            return false
        },
        validateThirdTab() {
            if (this.payment_option) {
                return true
            }

            this.showError('Verifique el metodo de pago')

            return false
        },
        validateFirstTab() {
            this.$v.$touch();

            if (this.$v.user.$invalid) {
                this.showError('Verifique el formulario')
            }

            return !this.$v.user.$invalid;
        },
        handleTabChange(prevIndex, nextIndex) {
            window.scrollTo(0, 0)
        },
        preparePay () {
            if (this.statusIsLoading()) return

            // const customerFormIsValid = this.$refs.customerForm.validate()
            // if (!customerFormIsValid) return

            this.statusLoading()

            window.scrollTo(0, 0)

            this.getRecaptchaToken('cartPay')
                .then(token => { this.pay() })
        },
        getDeptName (code) {
            const object = this.depts.find(o => o.code === code)
            if (object) {
                return object.name
            }
            return ''
        },
        getMunpName (code) {
            const object = this.munp.find(o => o.code === code)
            if (object) {
                return object.name
            }
            return ''
        },
        getSubmunpName (munCode, code) {
            const object = this.munp.find(o => o.code === munCode)

            if (object) {
                const subobject = object.submunicipalities.find(o => o.code === code)
                if (subobject) {
                    return subobject.name
                }
                return ''
            }
            return ''
        },
        pay () {
            const body = {
                recaptcha_token: this.recaptchaToken,

                payment_method: this.payment_option,
                customer: {
                    email: this.user.email,
                    first_name: this.user.fname,
                    last_name: this.user.lname,
                    identification: this.user.document,
                    cellphone: this.user.phone,
                    accept_terms_and_conditions: this.user.terms,
                },
                shipping: {
                    notes: this.user.messages,
                    departament_code: this.user.dept,
                    municipality_code: this.user.city,
                    location_address: this.user.address,
                    // location_city: 'required|string',
                },
                billing: {
                    departament_code: this.user.billing_dept,
                    municipality_code: this.user.billing_city,
                    location_address: this.user.billing_address,
                    // location_city: 'nullable|string'
                }
            }

            if (this.user.subcity && body.shipping.location_address) {
                const distritoName = this.getSubmunpName(this.user.city, this.user.subcity)
                body.shipping.location_address += ` (Distrito: ${distritoName})`
            }

            if (this.user.billing_subcity && body.billing.location_address) {
                const distritoName = this.getSubmunpName(this.user.billing_city, this.user.billing_subcity)
                body.billing.location_address += ` (Distrito: ${distritoName})`
            }

            if (this.user.billing) {
                body.billing.departament_code = body.shipping.departament_code
                body.billing.municipality_code = body.shipping.municipality_code
                body.billing.location_address = body.shipping.location_address
            }

            // this.statusLoading()

            this.$Models.Cart
                .pay(body)
                .then(res => {
                    // this.showSuccessMessage()

                    const data = res.data.result
                    this.checkout = data.checkout
                    this.order = data.order

                    this.initPaymentCheckout()

                    this.statusLoadingComplete()

                    // this.setDefaultValues() // check later
                })
                .catch((err) => {
                    this.showError(err)
                    this.statusLoadingFail()
                })
        },
        removeCartItem (product) {
            // if (this.readonly) { return null }
            if (this.statusIsLoading()) return

            this.statusLoading()

            this.$Models.Cart
                .remove(product.id)
                .then(res => {
                this.statusLoadingComplete()

                if (res.ok) {
                    // this.showSuccessMessage(res.message)
                    this.$store.commit('cart/setCart', res.cart)
                } else {
                    this.$store.commit('cart/setCart', res.cart)
                    this.showError(res.message)
                }
                })
                .catch(err => {
                    this.statusLoadingFail()
                    this.showError(err)
                })
        },
        updateItemCart (product) {
            if (this.statusIsLoading()) return

            this.statusLoading()

            this.$Models.Cart
                .updateItem({
                    id: product.id,
                    quantity: product.quantity
                })
                .then(res => {
                    this.statusLoadingComplete()

                    if (res.ok) {
                        // this.showSuccessMessage(res.message)
                        this.$store.commit('cart/setCart', res.cart)
                    } else {
                        this.$store.commit('cart/setCart', res.cart)
                        this.showError(res.message)
                    }
                })
                .catch(err => {
                    this.statusLoadingFail()
                    this.showError(err)
                })
        },
        addCoupon () {
            if (this.statusIsLoading()) return

            this.statusLoading()

            if (this.couponCode) {
                this.$Models.Cart.addCoupon(this.couponCode)
                    .then(res => {
                        this.statusLoadingComplete()

                        if (res.ok) {
                            this.$store.commit('cart/setCart', res.cart)
                            this.couponCode = ''
                            // this.showSuccessMessage(res.message)
                        } else {
                            this.$store.commit('cart/setCart', res.cart)
                            this.showError(res.message)
                        }
                    })
                    .catch((err) => {
                        this.statusLoadingFail()
                        this.showError(err)
                    })
            }
        },
        removeCoupon (coupon) {
            if (this.statusIsLoading()) return

            this.statusLoading()

            if (coupon) {
                this.$Models.Cart.removeCoupon(coupon.code)
                .then(res => {
                    this.statusLoadingComplete()

                    if (res.ok) {
                        // this.showSuccessMessage(res.message)
                        this.$store.commit('cart/setCart', res.cart)
                    } else {
                        this.$store.commit('cart/setCart', res.cart)
                        this.showError(res.message)
                    }
                })
                .catch(err => {
                    this.statusLoadingFail()
                    this.showError(err)
                })
            }
        },
    },
    computed: {
        ...mapState({
            cart: state => state.cart.cart,
            company: (state) => state.general.info
        })
    },
    head() {
        return {
            title: this.title,
            meta: [
                {
                    content: "Orden de compra - Organic Nails tienda oficial",
                },
            ],
        };
    },
    mixins: [
        MixinPay,
        LoadingMixin,
        RecaptchaMixin,
    ]
};
</script>
<style>
.vue-form-wizard .navbar .navbar-nav>li>a.wizard-btn,
.vue-form-wizard .wizard-btn {
    background: #000 !important;
    border-color: #000 !important;
    color: #fff !important;
}

.stepTitle .active {
    color: #000 !important;
}

.vue-form-wizard.sm .wizard-icon-circle {
    border-color: #000 !important;
}

.vue-form-wizard .wizard-icon-circle .wizard-icon-container {
    background: #000 !important;
}

.vue-form-wizard * {
    color: #000;
}

.vue-form-wizard .wizard-nav-pills>li.active>a .wizard-icon,
.vue-form-wizard .wizard-nav-pills>li.active>a:focus .wizard-icon,
.vue-form-wizard .wizard-nav-pills>li.active>a:hover .wizard-icon {
    color: #fff !important;
}

.vue-form-wizard .wizard-progress-bar {
    background-color: #000 !important;
}
</style>
