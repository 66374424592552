import { format } from '@/utils/currency'

/**
 *
 *
 * @param {int|string} value
 * @return strign
 */
function currencyFormat (value) {
  return format(value)
}

export default currencyFormat
