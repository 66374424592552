import Model from '@/api/ORM/Model.js'

import { RawConnection } from '@/api/ORM/connection/index'

class Customer extends Model {
  static endpoint = '/api/v1/customers'

  static verify (body) {
    const connection = new RawConnection()
    const url = '/api/v1/customers/verify'
    return connection.post(url, body)
      .then(result => result.data.data.result)
  }
}

export default Customer
