import { RawConnection } from '@/api/ORM/connection/index'

class Cart {
  static pay (body) {
    const connection = new RawConnection()
    // const url = '/api/v1/cart/pay'
    const url = '/cart/pay'
    return connection.post(url, body)
      .then(result => result.data)
  }

  static add (product, quantity) {
    let dQuantity = 1
    if (quantity !== null) {
      dQuantity = quantity
    }
    const connection = new RawConnection()
    const url = '/cart/add'
    return connection.put(url, { product: { id: product.id, name: product.name, online_store_slug: product.online_store_slug }, quantity: dQuantity })
      .then(result => result.data)
  }

  static updateItem (data) {
    const connection = new RawConnection()
    const url = '/cart/item/update'
    return connection.put(url, data)
      .then(result => result.data)
  }

  static remove (id) {
    const connection = new RawConnection()
    const url = '/cart/remove'
    return connection.post(url, { id })
      .then(result => result.data)
  }

  static clear () {
    const connection = new RawConnection()
    const url = '/cart/clear'
    return connection.post(url)
      .then(result => result.data)
  }

  static get () {
    const connection = new RawConnection()
    const url = '/cart/get'
    return connection.get(url)
      .then(result => result.data)
  }

  static addCoupon (code) {
    const connection = new RawConnection()
    const url = '/cart/coupon/add'
    return connection.post(url, { code })
      .then(result => result.data)
  }

  static removeCoupon (code) {
    const connection = new RawConnection()
    const url = '/cart/coupon/remove'
    return connection.post(url, { code })
      .then(result => result.data)
  }
}

export default Cart
