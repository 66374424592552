<template>
    <div>
        <b-overlay fixed :show="loadingPage" no-wrap :opacity="0.30"></b-overlay>

        <Header />
        <transition name="fade" mode="out-in">
            <slot />
        </transition>
        <Footer />
        <a :href="`https://api.whatsapp.com/send?phone=${company.contact_cellphone_indicator}${company.contact_cellphone}`" target="_blank" style="width: 50px; height: 50px; position: fixed; bottom: 40px; right: 30px; font-size: 0; z-index: 99;">
            <img :src="logo_ws" alt="whatsapp" style="width: 100%; height: auto;">
        </a>
    </div>
</template>

<script>
import Header from "@/components/layouts/header.vue";
import Footer from "@/components/layouts/footer.vue";
import { mapState, mapGetters } from "vuex";

export default {
    name: "Layout",
    components: {
        Header,
        Footer
    },
    data(){
        return {
            logo_ws: require("@/assets/img/common/btn-ws.png")
        }
    },
    computed: {
        ...mapGetters({
            company: "general/allInfo",
        }),
        ...mapState('general', {
            loadingPage: state => state.pageIsLoading,
        })
    }
};
</script>
